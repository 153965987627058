import { motion } from "framer-motion";

export default function ProjectButtonSmall({ src, children }) {
  return (
    <motion.div
      className="h-[24em] md:h-[26em] font-bold text-white pr-4 md:pr-8 pl-8 md:pl-16 bg-no-repeat bg-bottom bg-cover "
      style={{ backgroundImage: `url(${src})` }}
      initial={{
        boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.0)",
      }}
      whileHover={{
        scale: 1.05,

        boxShadow: "5px 5px 25px rgba(0, 0, 0, 0.15)",

        transition: { ease: "anticipate", duration: 0.6 },
      }}
    >
      <div className="relative top-48 lg:top-36">{children}</div>
    </motion.div>
  );
}
