export default function ImgDescription({ children }) {
  return (
    <div>
      {children && (
        <p className="ml-6 sm:ml-0 text-sm pb-4 pl-1 lg:pb-0 opacity-60">
          {children}
        </p>
      )}
    </div>
  );
}
