import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import ImgDescription from "./ImgDescription";

export default function ImageGrid({
  img1,
  img2,
  img3,
  img4,
  img1_placeholder,
  img2_placeholder,
  img3_placeholder,
  img4_placeholder,
  img1_desc,
  img2_desc,
  img3_desc,
  img4_desc,
}) {
  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2 pb-14 pt-8">
      <div>
        <LazyLoadImage
          alt="Portfolio visuals from a grid"
          effect="blur"
          src={img1}
          placeholderSrc={img1_placeholder}
        />
        <ImgDescription>{img1_desc}</ImgDescription>
      </div>
      <div>
        <LazyLoadImage
          alt="Portfolio visuals from a grid"
          effect="blur"
          src={img2}
          placeholderSrc={img2_placeholder}
        />
        <ImgDescription>{img2_desc}</ImgDescription>
      </div>
      {img3 && (
        <div>
          <LazyLoadImage
            alt="Portfolio visuals from a grid"
            effect="blur"
            src={img3}
            placeholderSrc={img3_placeholder}
          />
        </div>
      )}
      {img4 && (
        <div>
          <LazyLoadImage
            alt="Portfolio visuals from a grid"
            effect="blur"
            src={img4}
            placeholderSrc={img4_placeholder}
          />
          <ImgDescription>{img4_desc}</ImgDescription>
        </div>
      )}

      {/* <img alt="Portfolio visuals from a grid" src={img1} />
      <img alt="Portfolio visuals from a grid" src={img2} />
      {img3 && <img alt="Portfolio visuals from a grid" src={img3} />}
      {img4 && <img alt="Portfolio visuals from a grid" src={img4} />} */}
    </div>
  );
}
