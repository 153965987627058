import ImgHero from "./hero.jpg";
import Img00 from "./00_cta_image.png";
import Img00_placeholder from "./00_cta_image.png";
import Img01 from "./01_learnings.jpg";
import Img01_placeholder from "./01_learnings.jpg";
import Img02 from "./02_target_user.jpg";
import Img02_placeholder from "./02_target_user.jpg";
import Img03 from "./03_branding.jpg";
import Img03_placeholder from "./03_branding.jpg";
import Img04 from "./04_landing_page.jpg";
import Img04_placeholder from "./04_landing_page.jpg";
import Img04B from "./04_learnings.jpg";
import Img04B_placeholder from "./04_learnings.jpg";
import Img05 from "./05_system_overview.jpg";
import Img05_placeholder from "./05_system_overview.jpg";
import Img06 from "./06_cohort_analisis.jpg";
import Img06_placeholder from "./06_cohort_analisis.jpg";
import Img07 from "./07_next_steps.jpg";
import Img07_placeholder from "./07_next_steps.jpg";

const Imgs = {
  ImgHero,
  Img00,
  Img01,
  Img02,
  Img03,
  Img04,
  Img04B,
  Img05,
  Img06,
  Img07,

  Img00_placeholder,
  Img01_placeholder,
  Img02_placeholder,
  Img03_placeholder,
  Img04_placeholder,
  Img04B_placeholder,
  Img05_placeholder,
  Img06_placeholder,
  Img07_placeholder,
};
export default Imgs;
