import ImgHero from "./hero.jpg";
import Img01 from "./01.gif";
import Img01_placeholder from "./01_placeholder.jpg";
import Img02 from "./02.jpg";
import Img02_placeholder from "./02_placeholder.jpg";
import Img03 from "./03.gif";
import Img03_placeholder from "./03_placeholder.jpg";
import Img04 from "./04.jpg";
import Img04_placeholder from "./04_placeholder.jpg";
import Img05 from "./05.gif";
import Img05_placeholder from "./05_placeholder.jpg";
import Img06 from "./06.jpg";
import Img06_placeholder from "./06_placeholder.jpg";
import Img07 from "./07.jpg";
import Img07_placeholder from "./07_placeholder.jpg";

const Imgs = {
  ImgHero,
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  Img06,
  Img07,
  Img01_placeholder,
  Img02_placeholder,
  Img03_placeholder,
  Img04_placeholder,
  Img05_placeholder,
  Img06_placeholder,
  Img07_placeholder,
};
export default Imgs;
