import { motion, AnimatePresence } from "framer-motion";

export default function FullBleedBlock({ children }) {
  return (
    <AnimatePresence>
      <motion.div
        // className="bg-red mx-auto px-8 md:px-20 pt-16 pb-0"
        className="w-screen mx-0 pt-8 pb-16"
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div className="max-w-screen-lg md:flex grid-cols-1 md:grid-cols-2 color-white mx-auto px-8 md:px-20 pt-16 pb-0">
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
