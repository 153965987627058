import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import ImgDescription from "./ImgDescription";

export default function Image({ src, placeholder, desc }) {
  return (
    <div className="pb-14 pt-8 min-h-[150px]">
      <LazyLoadImage
        alt={desc}
        effect="blur"
        threshold="500"
        src={src}
        placeholderSrc={placeholder}
      />
      <ImgDescription>{desc}</ImgDescription>
    </div>
  );
}
