import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";

import Header from "../components/Header";

import HeaderBig from "../components/HeaderBig";
import Image from "../components/Image";
import ProjectButtonSmall from "../components/ProjectButtonSmall";
import ProjectHeadline from "../components/ProjectHeadline";
import ProjectTagSmall from "../components/ProjectTagSmall";
import ImageGrid from "../components/ImageGrid";

import ParagraphSection from "../components/ParagraphSection";
import ProjectFooter from "../components/ProjectFooter";

import ImageBgAkar from "../assets/projectsImages/akar.jpg";

import ImageUISelection from "../assets/projectsImages/uis.jpg";
import ImageBgNectar from "../assets/projectsImages/nectar.jpg";
import ImageBgReflektt from "../assets/projectsImages/reflektt.jpg";
import Imgs from "../assets/landd/Imgs";

// PROJECT RELATED IMAGES

const ProjectLD = ({ imgHero }) => {
  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />
      <div className="relative -top-60 md:-top-[20em]">
        {/* CONTENT STARTS HERE */}
        <div className="max-w-screen-lg mx-auto  pt-8">
          {/* HEADER + PARAGRAPH */}
          <ProjectHeadline
            subtitle="Summary of"
            title="My involvement in Learning and Development at IDEO"
          />
          <ParagraphSection>
            <Header>Overview</Header>
            <Text>
              As my IDEO journey progressed, my focus evolved beyond project
              success to amplifying my impact across the organization. Engaging
              in community building, knowledge sharing, and creating an
              environment for growth became central to my contributions. The
              following highlights key initiatives I was involved in.
            </Text>
          </ParagraphSection>
          {/* TEAM */}
          <ParagraphSection>
            <Header>Organizing learning program for project leads</Header>
            <Text>
              In pursuit of leadership development, I co-lead the organization
              of Project Lead Studio at IDEO Shanghai. Comprising four
              chapters—Client Management, Improving Content, Leading a Team, and
              Finding Your Own Leadership Style—the initiative aimed to nurture
              growth through various formats, including talks, panels, and
              workshops. Sessions featured experienced Leads and Directors,
              fostering a collaborative learning environment.
            </Text>
          </ParagraphSection>
          <Image src={Imgs.Img01} placeholder={Imgs.Img01_placeholder} />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Shaping Interaction Community </Header>
            <Text>
              As the most senior Interaction Designer, I was responsible for
              shaping the Interaction community at our location and fostering
              collaboration with the Tokyo office. Through intro sessions for
              new hires, I highlighted the nuances of Interaction design at
              IDEO, emphasizing collaboration and mutual support. The sessions,
              named 101 and 201, aimed to bridge perspectives and inspire
              collective growth.
            </Text>
          </ParagraphSection>
          <Image src={Imgs.Img02} placeholder={Imgs.Img02_placeholder} />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>
              Initiating a series of events with external collaborators
            </Header>
            <Text>
              I co-orchestrated Together Club, an internal initiative bridging
              the IDEO Shanghai design community with freelancers. This series
              of events facilitated reflection, inspiration, and our ways of
              working. I collaborated on speaker selection, story development,
              and internal promotion, creating an engaging platform for IDEOers
              and freelancers to share insights and elevate project quality.
            </Text>
          </ParagraphSection>
          <Image src={Imgs.Img03} placeholder={Imgs.Img03_placeholder} />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Sharing my knowledge with young professionals</Header>
            <Text>
              In 2022, I contributed to Collectivo23, a learning hub for South
              American professionals navigating the intersection of design,
              business, and technology. Over a year, I delivered five
              presentations and engaged in student evaluation sessions, focusing
              on storytelling, effective communication, prototyping, and the
              designer's role in consulting.
            </Text>
          </ParagraphSection>
          <Image src={Imgs.Img04} placeholder={Imgs.Img04_placeholder} />

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>
              Establishing principles of collaboration with external researchers
            </Header>
            <Text>
              Drawing from my experience as a Design Lead on projects with
              external researchers in Southeast Asia, I shared insights to
              enhance collaboration methods at IDEO Shanghai. The session
              addressed mindset shifts, provided HowTos for collaboration steps,
              and offered advice, becoming a valuable resource for teams
              navigating external collaborations.
            </Text>
          </ParagraphSection>
          <ImageGrid
            img1={Imgs.Img05}
            img2={Imgs.Img06}
            img3={Imgs.Img07}
            img4={Imgs.Img08}
            img1_placeholder={Imgs.Img05_placeholder}
            img2_placeholder={Imgs.Img06_placeholder}
            img3_placeholder={Imgs.Img07_placeholder}
            img4_placeholder={Imgs.Img08_placeholder}
          />
        </div>
      </div>
      {/* PROJECT FOOTER */}
      <ProjectFooter>
        {/* <Link to={`/safety/`}>
          <ProjectButtonSmall src={ImageBgAkar}>
            <ProjectTagSmall>Research & Define</ProjectTagSmall>
            <HeaderBig>
              Bringing human lens to increase safety at palm oil plantations
            </HeaderBig>
          </ProjectButtonSmall>
        </Link> */}

        <Link to={`/ui_selection/`}>
          <ProjectButtonSmall src={ImageUISelection}>
            <ProjectTagSmall>Examples of</ProjectTagSmall>
            <HeaderBig>User interactions and interfaces</HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/reflektt/`}>
          <ProjectButtonSmall src={ImageBgReflektt}>
            <ProjectTagSmall>Design & Launch</ProjectTagSmall>
            <HeaderBig>Building an AI tool for reflective journaling</HeaderBig>
          </ProjectButtonSmall>
        </Link>
        {/* <Link to={`/blood_testing/`}>
          <ProjectButtonSmall src={ImageBgNectar}>
            <ProjectTagSmall>Design & Detail</ProjectTagSmall>
            <HeaderBig>
              Building a blood testing device for an Emergency Room environment
            </HeaderBig>
          </ProjectButtonSmall>
        </Link> */}
      </ProjectFooter>
    </div>
  );
};

export default ProjectLD;
