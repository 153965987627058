import TeamSection from "../components/TeamSection";
import TeamMember from "../components/TeamMember";

import ImgTeam01 from "../assets/lincoln/ImgTeam01.png";
import ImgTeam02 from "../assets/lincoln/ImgTeam02.png";
import ImgTeam03 from "../assets/lincoln/ImgTeam03.png";
import ImgTeam04 from "../assets/lincoln/ImgTeam04.png";
import ImgTeam05 from "../assets/lincoln/ImgTeam05.png";

export default function TeamSectionLincoln({ children }) {
  return (
    <TeamSection>
      <TeamMember name="Piotr Kuklo" role="Project Lead" profile={ImgTeam02} />
      <TeamMember
        name="Chuli Duan"
        role="Design Researcher"
        profile={ImgTeam05}
      />
      <TeamMember
        name="Junchao Lv"
        role="Interaction Designer"
        profile={ImgTeam04}
      />
      <TeamMember
        name="Tiffany Hsieh"
        role="Freelance Designer"
        profile={ImgTeam01}
      />
      <TeamMember
        name="Ying Wang"
        role="Freelance Designer"
        profile={ImgTeam03}
      />
    </TeamSection>
  );
}
