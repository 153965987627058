import * as React from "react";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/solid";

const NoMatch = () => {
  return (
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold">404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p class="mt-6 text-base leading-7 text-neutral-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div class="mt-10 ">
          <a
            href="#"
            class=" text-neutral-500 underline flex items-center justify-center gap-x-3 underline"
          >
            <ArrowSmallLeftIcon
              className="h-6 w-6 ml-1 mt-0"
              aria-hidden="true"
            />
            Go Back{" "}
          </a>
        </div>
      </div>
    </main>
  );
};

export default NoMatch;
