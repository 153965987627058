import TeamSection from "../components/TeamSection";
import TeamMember from "../components/TeamMember";

import ImgTeam01 from "../assets/akar/ImgTeam01.png";
import ImgTeam02 from "../assets/akar/ImgTeam02.png";
import ImgTeam03 from "../assets/akar/ImgTeam03.png";
import ImgTeam04 from "../assets/akar/ImgTeam04.png";
import ImgTeam05 from "../assets/akar/ImgTeam05.png";
import ImgTeam06 from "../assets/akar/ImgTeam06.png";

export default function TeamSectionHiro({ children }) {
  return (
    <TeamSection>
      <TeamMember name="Piotr Kuklo" role="Project Lead" profile={ImgTeam01} />
      <TeamMember
        name="Hiromi Hayashihara"
        role="Design Researcher"
        profile={ImgTeam02}
      />
      <TeamMember
        name="Mateus Situmorang"
        role="Design Researcher"
        profile={ImgTeam03}
      />
      <TeamMember
        name="Andrew Michael"
        role="Videographer"
        profile={ImgTeam04}
      />
      <TeamMember
        name="Tu-Nga Nguyễn"
        role="Communication Designer"
        profile={ImgTeam05}
      />
      <TeamMember
        name="Inggar U. Ulhasanah"
        role="Interpreter"
        profile={ImgTeam06}
      />
    </TeamSection>
  );
}
