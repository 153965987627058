import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";

import { motion, AnimatePresence } from "framer-motion";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import TextSmall from "../components/TextSmall";
import TextItalic from "../components/TextItalic";
import TextNoPadding from "../components/TextNoPadding";

import Header from "../components/Header";
import HeaderSmall from "../components/HeaderSmall";
import HeaderBig from "../components/HeaderBig";
import HeaderBigger from "../components/HeaderBigger";
import Image from "../components/Image";
import ImgDescription from "../components/ImgDescription";

import ProjectHeadline from "../components/ProjectHeadline";
import ProjectButtonSmall from "../components/ProjectButtonSmall";
import ProjectTagSmall from "../components/ProjectTagSmall";

import ShowMoreComponent from "../components/ShowMoreComponent";

import ShowMoreButtonKitt from "../components/ShowMoreButtonKitt";
import HideProcessButtonKitt from "../components/HideProcessButtonKitt";

import ParagraphSection from "../components/ParagraphSection";
import LightBgSection from "../components/LightBgSection";
import TwoColumns from "../components/TwoColumns";
import ThreeColumns from "../components/ThreeColumns";
import ImageGrid from "../components/ImageGrid";
import ProjectFooter from "../components/ProjectFooter";
import FullBleedBlock from "../components/FullBleedBlock";

import TeamSectionLincoln from "../components/TeamSectionLincoln";
import VideoSection from "../components/VideoSection";
import Imgs from "../assets/reflektt/Imgs";

import ReflekttLogo from "../assets/reflektt/logo.png";
import ReflekttLogo_placeholder from "../assets/reflektt/logo.png";

import ImageBgHiro from "../assets/projectsImages/hiro.jpg";
import ImageBgSudoku from "../assets/projectsImages/sudoku.jpg";
import ImageBgNectar from "../assets/projectsImages/nectar.jpg";
import ImageBgLincoln from "../assets/projectsImages/lincoln.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// PROJECT RELATED IMAGES

const ProjectReflektt = ({ imgHero }) => {
  const [showMore, setShowMore] = useState(false);
  const [insideProcess, setInsideProcess] = useState(false);
  const processRef = useRef(null);

  function handleClick() {
    setShowMore(!showMore);
  }
  function handleHideProcess() {
    // scroll to the process id top

    const element = document.getElementById("process_overview");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setShowMore(false);
    }, 600); // Adjust the delay as needed
  }

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInsideProcess(true);
        } else {
          setInsideProcess(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the container
      rootMargin: "0px",
      threshold: 0.1, // Adjust the threshold as needed
    });

    if (processRef.current) {
      observer.observe(processRef.current);
    }

    return () => {
      if (processRef.current) {
        observer.unobserve(processRef.current);
      }
    };
  }, []);

  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />

      {/* CONTENT STARTS HERE */}
      <div className="relative -top-60 md:-top-[20em]">
        <div className="max-w-screen-lg mx-auto pt-8">
          {/* HEADER + PARAGRAPH */}

          <ProjectHeadline
            subtitle="Design & Ship"
            title="Launching an AI tool for reflective journaling"
          />

          <ParagraphSection>
            <Header>Project Overview</Header>
            <Text>
              Reflektt is an AI-driven journaling tool designed to help users
              better understand their thoughts. By using AI prompts to guide the
              journaling experience, the tool aids users in reflecting deeply on
              their daily experiences, providing a structure for introspective
              thoughts.
            </Text>
          </ParagraphSection>
          {/* TEAM */}
          <ParagraphSection>
            <Header>My Role</Header>
            <Text>
              As the sole person behind the project, I handle the entire product
              lifecycle—from ideation to development, user research, product
              strategy, branding, and marketing. My role spans all areas,
              allowing me to balance technical execution with a strong focus on
              user experience.
            </Text>
          </ParagraphSection>

          {/* CTA */}
          <div className="bg-[#F0F6EC] text-gray-500 mb-8 md:mb-16 px-8 md:px-20 pt-8 md:pt-20 mt-20 md:mt-40">
            <div className="MainContent h-80 flex md:gap-24 justify-items-start justify-start items-end ">
              <div className="left w-1/2 md:w-5/12 my-20">
                <LazyLoadImage
                  width={120}
                  alt="Reflektt"
                  effect="blur"
                  threshold="500"
                  src={ReflekttLogo}
                  placeholderSrc={ReflekttLogo_placeholder}
                />
                <h3 className="text-lg md:text-xl mt-4 mb-4 md:mb-16">
                  Reflektt is currently in the stage of seeking product-market
                  fit.
                </h3>
                <a href="https://www.reflektt.com/">
                  <div className="Buttoncta p-4 bg-teal-700 rounded justify-center items-center gap-1.5 inline-flex hover:bg-[#0B4830]">
                    <div className="Label text-center text-neutral-50 text-xl font-bold leading-normal">
                      Go to the app
                    </div>
                  </div>
                </a>
              </div>
              <div className="Right w-1/2 md:w-7/12">
                <img
                  className="Img "
                  // className="Img hidden lg:inline relative -top-20 lg:-top-48 lg:-mt-[25px]"
                  src={Imgs.Img00}
                />
              </div>
            </div>
          </div>

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Experience overview</Header>
            <Text>
              Users can engage in a journaling process, effortlessly writing
              down their thoughts. When they press Enter after completing a
              paragraph, they recieve AI-generated follow-up questions, guiding
              them to think more critically and reflect more deeply.
            </Text>
          </ParagraphSection>

          <VideoSection url="https://youtu.be/PS5P9iU9ve0" />
          <ImgDescription>
            <span className="relative -top-8">
              Reflektt's core journaling experience, highlighting user
              interactions.
            </span>
          </ImgDescription>

          <ParagraphSection>
            <Header>Six Modes of Reflection</Header>
            <Text>
              Users can choose from six journaling modes, each designed for a
              specific reflection goal. Each mode includes a brief explanation
              to help users select the one that best aligns with their intent,
              creating a more focused experience.
            </Text>
          </ParagraphSection>
          <VideoSection url="https://youtu.be/QKiZL6QNzSc" />
          <ImgDescription>
            <span className="relative -top-8">
              User selecting one of six modes to personalize their journaling.
            </span>
          </ImgDescription>

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Distraction free experience</Header>
            <Text>
              Reflektt is designed to provide a distraction-free environment,
              where users can focus solely on writing. The sidebar contains all
              additional functionalities, ensuring that the core experience
              remains uninterrupted.
            </Text>
          </ParagraphSection>
          <VideoSection url="https://youtu.be/-iZHZ9i6vsA" />
          <ImgDescription>
            <span className="relative -top-8">
              Sidebar showcasing additional functionalities for easy navigation.
            </span>
          </ImgDescription>
        </div>
        <div className="mt-10 bg-[#06514E] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Process Overview</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>Initial Exploration</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    I researched the journaling space, focusing on user
                    interactions with reflective tools and their technologies.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Clarifying individual’s needs</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    I conducted user research through interviews and surveys to
                    identify users' pain points and motivations for journaling.
                  </span>
                </TextNoPadding>
              </div>
              <div id="process_overview" className="pb-10">
                <Header>Prototyping for data</Header>
                <TextNoPadding>
                  <span className="-pt-4 text-gray-100 opacity-70">
                    As the prototype reached a broader audience, I gathered data
                    points, enabling me to refine the user experience based on
                    real interactions.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>
        <div id="process" ref={processRef}>
          <Process showMore={showMore} />
          <div id="hide_process" className="h-5">
            <AnimatePresence>
              {showMore && insideProcess && (
                <HideProcessButtonKitt
                  handleClick={handleHideProcess}
                  bgColor="4D7D7B"
                  textColor="F4F1EC"
                />
              )}
            </AnimatePresence>
          </div>
        </div>
        {!showMore && (
          <div className="relative -mt-60  bg-gradient-to-t from-white to-transparent">
            <ShowMoreComponent>
              <ShowMoreButtonKitt handleClick={handleClick} />
            </ShowMoreComponent>
          </div>
        )}
      </div>
      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/blood_testing/`}>
          <ProjectButtonSmall src={ImageBgNectar}>
            <ProjectTagSmall>Design & Detail</ProjectTagSmall>
            <HeaderBig>
              Building a blood testing device for an Emergency Room environment
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/assistant/`}>
          <ProjectButtonSmall src={ImageBgLincoln}>
            <ProjectTagSmall>Design & Guide</ProjectTagSmall>
            <HeaderBig>
              Defining the role of Digital Assistant for highly demanding car
              owners
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
      </ProjectFooter>
    </div>
  );
};
function Process(showMore) {
  return (
    <AnimatePresence>
      <motion.div
        className={`max-w-screen-lg mx-auto bg-white pt-8  ${
          !showMore.showMore &&
          "opacity-20 h-[32rem] overflow-hidden transition-all"
        }`}
      >
        <ParagraphSection>
          <Header>MVPrototype</Header>
          <Text>
            Following the exploration in Figma, I focused on building a
            prototype with key features. Using the TipTap text editor framework,
            the prototype allowed users to receive AI prompts and save their
            notes, giving them a preview of the final experience.
          </Text>
        </ParagraphSection>
        <VideoSection url="https://youtu.be/WXsqGDH-qZI" />
        <ImgDescription>
          <span className="relative -top-8">
            Prototype created for initial research, illustrating key features.
          </span>
        </ImgDescription>
        {/* PARAGRAPH & IMAGE */}
        {showMore.showMore && (
          <div>
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Testing & Learning</Header>
              <Text>
                I invited 8 friends to test the prototype over several days to
                see how it integrated into their routines. I conducted
                interviews and informal chats to gather insights, which led to
                improvements in the design, such as adding six modes for better
                focus.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img01}
              placeholder={Imgs.Img01_placeholder}
              desc="Insights and learnings gathered from the initial research phase."
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Target user hypothesis</Header>
              <Text>
                Based on qualitative research, I developed a hypothesis around
                the target audience. This insight informed the advertisement
                strategy for the landing page, making the ad campaigns more
                efficient and focused on the right demographic.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img02}
              placeholder={Imgs.Img02_placeholder}
              desc="Hypothesis on two target user groups based on research findings."
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Branding</Header>
              <Text>
                The brand was crafted to exude calmness, positioning it as a
                high-quality tool in the journaling space. This distinctive
                branding helps differentiate Reflektt from competitors, building
                trust and appeal among potential users.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img03}
              placeholder={Imgs.Img03_placeholder}
              desc="Logo, brand colors, typography, and illustration style defining Reflektt."
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Landing page and data</Header>
              <Text>
                To gather quantitative data on user interest, I built a landing
                page with a waiting list option. By running Facebook ad
                campaigns, I collected promising results, which guided the next
                stages of product development.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img04}
              placeholder={Imgs.Img04_placeholder}
              desc="Landing page design featuring the 'Join our waiting list' section."
            />
            <Image
              src={Imgs.Img04B}
              placeholder={Imgs.Img04B_placeholder}
              desc="Insights from landing page performance and ad campaigns."
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>System Overview</Header>
              <Text>
                While the user interface is designed to be simple and intuitive,
                the backend presented several complex coding challenges that
                pushed me to grow my development skills.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img05}
              placeholder={Imgs.Img05_placeholder}
              desc="Technical overview of Reflektt's architecture and technologies used."
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Cohort analysis to track progress</Header>
              <Text>
                To better assess the progress, I developed a cohort analysis
                model. The key group, 'Used three times a week or more' is
                critical as it likely contains users who will convert to
                long-term, paid subscriptions. Growth in this cohort is a strong
                indicator that we're on the right path.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img06}
              placeholder={Imgs.Img06_placeholder}
              desc="Cohort analysis infographics presenting user segments metrics."
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Next Steps</Header>
              <Text>
                Reflektt is still in its early stages, with many exciting
                features yet to be explored. As I continue to iterate based on
                user feedback, the project became a platform for me to expand my
                skills in product development. Whether Reflektt becomes a major
                success or remains a passion project, it has already provided
                invaluable learning opportunities.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img07}
              placeholder={Imgs.Img07_placeholder}
              desc="Current project status and next steps for further development."
            />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectReflektt;
