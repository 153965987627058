import { ArrowSmallDownIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";

export default function ShowMoreButtonGBF({ handleClick, bgColor, textColor }) {
  return (
    <AnimatePresence>
      <button onClick={() => handleClick()}>
        <motion.div
          className={
            "bg-[#60A5FA] text-white rounded-m text-sm md:text-xl text-center flex items-center gap-2 px-16 md:px-28 py-8 shadow-2xl"
          }
          whileHover={{
            scale: 0.9,

            transition: { ease: "anticipate", duration: 0.6 },
          }}
        >
          <p>See the full process</p>
          <ArrowSmallDownIcon className="h-5 w-5" aria-hidden="true" />
        </motion.div>
      </button>
    </AnimatePresence>
  );
}
