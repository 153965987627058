import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

import "./App.css";

import Home from "./pages/Home";
import Protected from "./pages/Protected";
import Signin from "./pages/Signin";
import Project1 from "./pages/Project1";
import Project2 from "./pages/Project2";

import Project6 from "./pages/Project6";
import Project7 from "./pages/Project7";

import ProjectNectar from "./pages/ProjectNectar";
import ProjectKitt from "./pages/ProjectKitt";
import ProjectGBF from "./pages/ProjectGBF";
import ProjectAkar from "./pages/ProjectAkar";
import ProjectLD from "./pages/ProjectLD";
import ProjectSudoku from "./pages/ProjectSudoku";
import ProjectReflektt from "./pages/ProjectReflektt";

import NoMatch from "./pages/NoMatch";
import NavBar from "./components/NavBar";

import { AuthContextProvider } from "./context/AuthContext";

import ProtectedRoute from "./pages/ProtectedRoute";
import ScrollToTop from "./context/ScrollToTop";

import lincolnHero from "./assets/lincoln/hero.jpg";
import hiroHero from "./assets/hiro/hero.jpg";
import akarHero from "./assets/akar/hero.jpg";
import reflekttHero from "./assets/reflektt/hero.jpg";
import nectarHero from "./assets/nectar/hero.jpg";
import developmentHero from "./assets/landd/hero.jpg";
import sudokuHero from "./assets/sudoku/hero.jpg";
import uisHero from "./assets/uis/hero.jpg";

import ProjectList from "./pages/ProjectList";

const App = () => {
  const [userLoaded, setUserLoaded] = useState(false);

  return (
    <Router>
      <ScrollToTop />
      <div className="h-full mb-0 pb-0 bg-white">
        <NavBar id="top" />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route
            path="blood_testing"
            element={<ProjectNectar imgHero={nectarHero} />}
          />
          <Route
            path="assistant"
            element={<ProjectKitt imgHero={lincolnHero} />}
          />
          <Route path="projects_test" element={<ProjectList />} />
          <Route path="ui_selection" element={<Project6 imgHero={uisHero} />} />
          <Route
            path="reflektt"
            element={<ProjectReflektt imgHero={reflekttHero} />}
          />

          <Route path="stem" element={<ProjectGBF imgHero={hiroHero} />} />
          <Route path="safety" element={<ProjectAkar imgHero={akarHero} />} />
          <Route
            path="development"
            element={<ProjectLD imgHero={developmentHero} />}
          />
          <Route
            path="sudoku"
            element={<ProjectSudoku imgHero={sudokuHero} />}
          />

          <Route path="*" element={<NoMatch />} />
        </Routes>
        <div className="hidden">
          <img alt="Assistant" src={lincolnHero} />
          <img alt="STEM" src={hiroHero} />
          <img alt="Safety" src={akarHero} />
          <img alt="Development" src={developmentHero} />
          <img alt="Sudoku" src={sudokuHero} />
          <img alt="Uis" src={uisHero} />
        </div>
      </div>
    </Router>
  );
};

export default App;
