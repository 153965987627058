import ImgHero from "./hero.jpg";
import Img01 from "./01-user-context.jpg";
import Img01_placeholder from "./01-user-context-placeholder.jpg";
import Img02 from "./02-directions-start.jpg";
import Img02_placeholder from "./02-directions-start-placeholder.jpg";

import Img03A from "./03-prototype-foam.gif";
import Img03A_placeholder from "./03-prototype-foam_placeholder.jpg";
import Img03B from "./03-prototype-low-fidelity-22fps.gif";
import Img03B_placeholder from "./03-prototype-low-fidelity-22fps_placeholder.jpg";
import Img03C from "./03-testing-cards.jpg";
import Img03C_placeholder from "./03-testing-cards-placeholder.jpg";
import Img03D from "./03-testing-prototype.jpg";
import Img03D_placeholder from "./03-testing-prototype-placeholder.jpg";
import Img03E from "./03-collab.jpg";
import Img03E_placeholder from "./03-collab-placeholder.jpg";

import Img04 from "./04-directions-summary.jpg";
import Img04_placeholder from "./04-directions-summary-placeholder.jpg";
import Img05 from "./05-constrains.jpg";
import Img05_placeholder from "./05-constrains-placeholder.jpg";
import Img06 from "./06-prototype-final-flow.gif";
import Img06_placeholder from "./06-prototype-final-flow-placeholder.jpg";
import Img07 from "./07-overview.jpg";
import Img07_placeholder from "./07-overview-placeholder.jpg";
import Img08 from "./08-modules.jpg";
import Img08_placeholder from "./08-modules-placeholder.jpg";
import Img09 from "./09-adding-sample.jpg";
import Img09_placeholder from "./09-adding-sample-placeholder.jpg";
import Img10 from "./10-extra.jpg";
import Img10_placeholder from "./10-extra-placeholder.jpg";
import Img11 from "./11-states.jpg";
import Img11_placeholder from "./11-states-placeholder.jpg";
import Img12 from "./12-foam-prototype.jpg";
import Img12_placeholder from "./12-foam-prototype-placeholder.jpg";
import Img13 from "./13-candy-1.jpg";
import Img13_placeholder from "./13-candy-1-placeholder.jpg";
import Img14 from "./13-results.jpg";
import Img14_placeholder from "./13-results-placeholder.jpg";
import Img15 from "./13-notifications.jpg";
import Img15_placeholder from "./13-notifications-placeholder.jpg";
import Img16 from "./13-notifications-details.jpg";
import Img16_placeholder from "./13-notifications-details-placeholder.jpg";

const Imgs = {
  ImgHero,
  Img01,
  Img02,
  Img03A,
  Img03B,
  Img03C,
  Img03D,
  Img03E,
  Img04,
  Img05,
  Img06,
  Img07,
  Img08,
  Img09,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,

  Img01_placeholder,
  Img02_placeholder,
  Img03A_placeholder,
  Img03B_placeholder,
  Img03C_placeholder,
  Img03D_placeholder,
  Img03E_placeholder,
  Img04_placeholder,
  Img05_placeholder,
  Img06_placeholder,
  Img07_placeholder,
  Img08_placeholder,
  Img09_placeholder,
  Img10_placeholder,
  Img11_placeholder,
  Img12_placeholder,
  Img13_placeholder,
  Img14_placeholder,
  Img15_placeholder,
  Img16_placeholder,
};
export default Imgs;
