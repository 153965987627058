import * as React from "react";
import { useState, useEffect, useRef } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { HashLink as Link } from "react-router-hash-link";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import TextNoPadding from "../components/TextNoPadding";
import TextSmall from "../components/TextSmall";
import TextItalic from "../components/TextItalic";

import Header from "../components/Header";
import HeaderBiggest from "../components/HeaderBiggest";
import ShowMoreComponent from "../components/ShowMoreComponent";
import HeaderBig from "../components/HeaderBig";
import HeaderBigger from "../components/HeaderBigger";
import ProjectHeadline from "../components/ProjectHeadline";
import Image from "../components/Image";
import TallImage from "../components/TallImage";
import ShowMoreButtonNectar from "../components/ShowMoreButtonNectar";
import HideProcessButtonNectar from "../components/HideProcessButtonNectar";
import ProjectButtonSmall from "../components/ProjectButtonSmall";

import ProjectTagSmall from "../components/ProjectTagSmall";
import ImageGrid from "../components/ImageGrid";

import ParagraphSection from "../components/ParagraphSection";
import FullBleedBlock from "../components/FullBleedBlock";
import LightBgSection from "../components/LightBgSection";
import TwoColumns from "../components/TwoColumns";

import ProjectFooter from "../components/ProjectFooter";

import ImageBgHiro from "../assets/projectsImages/hiro.jpg";
import ImageBgLincoln from "../assets/projectsImages/lincoln.jpg";

import VideoSection from "../components/VideoSection";
import Imgs from "../assets/nectar/Imgs";
import TeamSectionNectar from "../components/TeamSectionNectar";
import TallImageWider from "../components/TallImageWider";
// PROJECT RELATED IMAGES

const ProjectNectar = ({ imgHero }) => {
  const [showMore, setShowMore] = useState(false);
  const [insideProcess, setInsideProcess] = useState(false);
  const processRef = useRef(null);

  function handleClick() {
    setShowMore(!showMore);
  }
  function handleHideProcess() {
    // scroll to the process id top

    const element = document.getElementById("process_overview");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setShowMore(false);
    }, 600); // Adjust the delay as needed
  }

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInsideProcess(true);
        } else {
          setInsideProcess(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the container
      rootMargin: "0px",
      threshold: 0.1, // Adjust the threshold as needed
    });

    if (processRef.current) {
      observer.observe(processRef.current);
    }

    return () => {
      if (processRef.current) {
        observer.unobserve(processRef.current);
      }
    };
  }, []);

  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />

      {/* CONTENT STARTS HERE */}
      <div className="relative -top-60 md:-top-[20em]">
        <div className="max-w-screen-lg mx-auto pt-8">
          {/* HEADER + PARAGRAPH */}
          <ProjectHeadline
            subtitle="Design & Detail"
            title="Building a blood testing device for an Emergency Room environment"
          />
          <ParagraphSection>
            <Header>Project Overview</Header>
            <Text>
              The team partnered with a blood testing company, excelling in a
              laboratory testing environment. Our business objective: leverage
              client's expertise to create a groundbreaking product for hospital
              emergency rooms.
            </Text>
          </ParagraphSection>
          {/* TEAM */}
          <ParagraphSection>
            <Header>Team & My Role</Header>
            <Text>
              As the sole Interaction Designer, I led the development of the
              user experience from concept to delivery, seamlessly integrating
              digital and physical elements to ensure a cohesive and tangible
              solution.
            </Text>
          </ParagraphSection>
          <TeamSectionNectar />
          {/* HMW */}
          <div className="bg-[#EFF6F6] text-[#02282D]">
            <LightBgSection>
              <h3 className="text-2xl md:text-3xl ">How Might We</h3>
              <HeaderBigger>
                design a testing machine tailored for high-paced emergency
                scenarios to empower nurses to execute tasks with heightened
                confidence?
              </HeaderBigger>
            </LightBgSection>
          </div>
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Streamlined process of adding a vial</Header>
            <Text>
              Nurses often handle high-risk tasks under significant time
              pressure. By tapping a call-to-action (CTA) button or scanning a
              barcode, they can quickly add samples for testing. The system
              ensures efficient testing by intelligently queuing samples based
              on their assigned priority.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img13}
            placeholder={Imgs.Img13_placeholder}
            desc="A nurse adding a blood sample to the machine"
          />
          <div className="px-20">
            <Image
              src={Imgs.Img06}
              placeholder={Imgs.Img06_placeholder}
              desc="The main flow: adding a sample to the queue"
            />
          </div>
          <Image
            src={Imgs.Img07}
            placeholder={Imgs.Img07_placeholder}
            desc="The logic of the main flow"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Quick Access to Results</Header>
            <Text>
              Nurses need to quickly access test results to update their team on
              a patient's health status. With a single tap, they can view the
              most recent results or search for a specific sample by its ID. The
              system also offers options to print the results or send them via
              WeChat, ensuring efficient communication and swift action.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img14}
            placeholder={Imgs.Img14_placeholder}
            desc="Finished results are moved to a separate tab for easy access"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Machine status and notification system</Header>
            <Text>
              Nurses need to quickly grasp the blood testing machine's status
              and receive recommendations for resolving any errors. Color-coded
              indicators and concise descriptions enable nurses to confidently
              troubleshoot issues and maintain efficient operation.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img15}
            placeholder={Imgs.Img15_placeholder}
            desc="A warning notification appears above call-to-action button"
          />
          <Image
            src={Imgs.Img16}
            placeholder={Imgs.Img16_placeholder}
            desc="Structure and hierarchy of notifications"
          />
          {/* PARAGRAPH & IMAGE */}
        </div>{" "}
        {/* FULL BLEED BLOCK - PROCESS OVERVIEW */}
        <div className="mt-10 bg-[#02282D] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Process Overview</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>Understanding context</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    We gain insights into the specific challenges and workflows
                    nurses face to design more effective solutions.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Prototyping</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    The team created low-fidelity prototypes to develop physical
                    and digital interactions, gradually refining concepts for
                    testing with nurses.
                  </span>
                </TextNoPadding>
              </div>
              <div id="process_overview" className="pb-10">
                <Header>Iterating on nurses feedback</Header>
                <TextNoPadding>
                  <span className="-pt-4 text-gray-100 opacity-70">
                    We continuously improved designs by incorporating direct
                    feedback from nurses to ensure practical and user-friendly
                    solutions.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>
        <div id="process" ref={processRef}>
          <Process showMore={showMore} />
          <div id="hide_process" className="h-5">
            <AnimatePresence>
              {showMore && insideProcess && (
                <HideProcessButtonNectar handleClick={handleHideProcess} />
              )}
            </AnimatePresence>
          </div>
        </div>
        {!showMore && (
          <div className="relative -top-60 bg-gradient-to-t from-white to-transparent">
            <ShowMoreComponent>
              <ShowMoreButtonNectar handleClick={handleClick} />
            </ShowMoreComponent>
          </div>
        )}
        {/* FULL BLEED BLOCK - SPECS & DELIVERABLES */}
        <div className="bg-[#02282D] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Specs & Deliverables</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>Modules and Interface structure </Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    Outlined arrangement and interaction of different modules
                    and interfaces to ensure a cohesive user experience.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Supplement flows</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    Additional user flows to cover edge cases and enhance
                    overall usability.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>System Logic</Header>
                <TextNoPadding>
                  <span className="-pt-4 text-gray-100 opacity-70">
                    The underlying logic and rules that govern system behavior
                    to ensure consistent and reliable performance.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>
        <div className="max-w-screen-lg mx-auto bg-white pt-8">
          <div className="bg-[#EFF6F6] text-[#02282D]">
            <LightBgSection>
              <TallImageWider
                src={Imgs.Img08}
                placeholder={Imgs.Img08_placeholder}
                desc="Sections and modules of the main interface"
              />
            </LightBgSection>
          </div>
          <div className="bg-[#EFF6F6] text-[#02282D]">
            <LightBgSection>
              <TallImageWider
                src={Imgs.Img11}
                placeholder={Imgs.Img11_placeholder}
                desc="The logic of sample's states and properties"
              />
            </LightBgSection>
          </div>

          <Image
            src={Imgs.Img09}
            placeholder={Imgs.Img09_placeholder}
            desc="Main users' flows"
          />
          <Image
            src={Imgs.Img10}
            placeholder={Imgs.Img10_placeholder}
            desc="Additional screens and the user interface style sheet"
          />

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Finalizing Design for Implementation</Header>
            <Text>
              Our project was followed up by a phase focused on finalizing the
              design to conduct the implementation. The new team fine-tuned the
              design and refined UI elements. The team detailed the user
              interface style sheet and addressed previously unexplored user
              flows.
            </Text>
          </ParagraphSection>
          <VideoSection url="https://youtu.be/6zJlE3sb928" />

          {/* IN OTHERS EYES */}

          <div className="bg-[#EFF6F6] text-[#02282D]">
            <LightBgSection>
              <HeaderBig>In client's eyes</HeaderBig>
              <TwoColumns>
                <div className="pt-8 md:pt-6">
                  <TextItalic>
                    Now we have a rule that is inspired by the cardboard
                    prototypes IDEO made. Any material procurements are
                    prohibited until the cardboard is made and approved by the
                    team. It is the cheapest way of proof of concept. Besides,
                    we have planned a new space for prototyping. Making things
                    tangible is the best way to make progress in the innovation.
                  </TextItalic>
                  <TextSmall>- Client's CEO</TextSmall>
                </div>
                <div className="pt-1 md:pt-0">
                  <Image
                    src={Imgs.Img12}
                    placeholder={Imgs.Img12_placeholder}
                  />
                </div>
              </TwoColumns>
            </LightBgSection>
          </div>
          {/* REFLECTIONS*/}
          <div className="bg-[#EFF6F6] text-[#02282D]">
            <LightBgSection>
              <HeaderBig>My Learnings & Reflections</HeaderBig>

              <div className="pt-10 md:pt-8">
                <Header>Leading by example</Header>
                <p className="text-xl leading-relaxed pt-3 opacity-80">
                  My initial responsibility was to lead the design of a digital
                  workstream but after joining a project I also wanted to help
                  the team improve our collaboration process. Within hours, I
                  built first prototype, fostering a productive discussion that
                  started the enhancement of the team's dynamics that lasted
                  throughout the remaining part of the project. This experience
                  taught me the influential power of leading by example,
                  emphasizing that leadership is not just about instructions but
                  also about demonstrating effective approaches.
                </p>
              </div>
            </LightBgSection>
          </div>
        </div>
      </div>
      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/assistant/`}>
          <ProjectButtonSmall src={ImageBgLincoln}>
            <ProjectTagSmall>Design & Guide</ProjectTagSmall>
            <HeaderBig>
              Defining the role of Digital Assistant for highly demanding car
              owners
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/stem/`}>
          <ProjectButtonSmall src={ImageBgHiro}>
            <ProjectTagSmall>Design & Explore</ProjectTagSmall>
            <HeaderBig>
              Designing a development program for STEM Teachers in the
              Philippines
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
      </ProjectFooter>
    </div>
  );
};

function Process(showMore) {
  return (
    <AnimatePresence>
      <motion.div
        className={`max-w-screen-lg mx-auto bg-white pt-8  ${
          !showMore.showMore &&
          "opacity-20 h-[32rem] overflow-hidden transition-all"
        }`}
      >
        <ParagraphSection>
          <Header>User-centric context</Header>
          <Text>
            The 3-week research unveiled the demanding lives of Emergency Room
            nurses. In this fast-paced environment, there is no time for delays
            or mistakes. Every minute could be critical to a patient's health.
            In this high-paced setting, our device needed to seamlessly
            integrate into their tasks, enhance performance, and relieve rather
            than burden.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img01}
          placeholder={Imgs.Img01_placeholder}
          desc="ER context and design principles"
        />
        {/* PARAGRAPH & IMAGE */}
        {showMore.showMore && (
          <div>
            <ParagraphSection>
              <Header>Two directions for the blood sampling method</Header>
              <Text>
                Through sacrificial concepts and prioritization with the
                client's stakeholders, the team narrowed down the blood sampling
                method to two directions. To grasp their potential, we
                transformed sketches into physical/digital prototypes, elevating
                the fidelity of our thinking.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img02}
              placeholder={Imgs.Img02_placeholder}
              desc="Two potential design directions"
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Prototyping to think</Header>
              <Text>
                Building prototypes allowed us to clarify the intricacies of the
                machine's functionalities and gain clarity on how to integrate
                digital and physical components. To expedite iterations, I
                created foam and low-fidelity digital prototypes, making our
                discussions tangible and accelerating the refinement process.
              </Text>
            </ParagraphSection>
            <ImageGrid
              img1={Imgs.Img03E}
              img2={Imgs.Img03A}
              img1_placeholder={Imgs.Img03E_placeholder}
              img2_placeholder={Imgs.Img03A_placeholder}
              img1_desc="Team collaboration with low fidelity prototype"
              img2_desc="Foam board prototype representing the concept's mechanics"
            />
            <div className="bg-[#EFF6F6] text-[#02282D]">
              <LightBgSection>
                <TallImage
                  src={Imgs.Img03B}
                  placeholder={Imgs.Img03B_placeholder}
                  desc="One of the low fidelity prototypes tested with nurses"
                />
              </LightBgSection>
            </div>

            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Scenario based testing with nurses</Header>
              <Text>
                We tested the prototypes during 4 interview sessions with ER
                nurses. The nurses were tasked with performing a blood testing
                procedure and machine maintenance based on the provided
                scenarios.
              </Text>
            </ParagraphSection>
            <ImageGrid
              img1={Imgs.Img03C}
              img2={Imgs.Img03D}
              img3=""
              img4=""
              img1_placeholder={Imgs.Img03C_placeholder}
              img2_placeholder={Imgs.Img03D_placeholder}
              img3_placeholder=""
              img4_placeholder=""
              img1_desc="Interview with a nurse"
              img2_desc="Prototype testing with a nurse"
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Deciding on the final direction</Header>
              <Text>
                Testing revealed crucial insights. We learned to prioritize
                intuitiveness over flexibility, leading us to focus on Direction
                B. Pivoting swiftly based on feedback, we optimized the machine
                for individual patient use.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img04}
              placeholder={Imgs.Img04_placeholder}
              desc="Evaluation and deciding on Direction B"
            />

            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>
                <span className="opacity-50">Iteration:</span> Mechanical
                constraints
              </Header>
              <Text>
                Thanks to close collaborations with the engineering team we
                realized the risk of inputting multiple vials without closing
                the slot. We swiftly adapted the digital experience. Prototyping
                allowed us to address mechanical constraints and ensure safety
                during the procedure.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img05}
              placeholder={Imgs.Img05_placeholder}
              desc="Removing multiple vials input due to mechanical constrains"
            />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectNectar;
