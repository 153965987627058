import TeamSection from "../components/TeamSection";
import TeamMember from "../components/TeamMember";

import ImgTeam01 from "../assets/hiro/ImgTeam01.png";
import ImgTeam02 from "../assets/hiro/ImgTeam02.png";
import ImgTeam03 from "../assets/hiro/ImgTeam03.png";
import ImgTeam04 from "../assets/hiro/ImgTeam04.png";
import ImgTeam05 from "../assets/hiro/ImgTeam05.png";

export default function TeamSectionHiro({ children }) {
  return (
    <TeamSection>
      <TeamMember name="Piotr Kuklo" role="Project Lead" profile={ImgTeam01} />
      <TeamMember
        name="Yuriko Yamaguchi"
        role="Design Researcher"
        profile={ImgTeam02}
      />
      <TeamMember
        name="Dingo Francisco"
        role="Communication Designer"
        profile={ImgTeam03}
      />
      <TeamMember
        name="Yue Yuan"
        role="Interaction Designer"
        profile={ImgTeam04}
      />
      <TeamMember
        name="Ying Wang"
        role="Freelance Designer"
        profile={ImgTeam05}
      />
    </TeamSection>
  );
}
