import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";

import { motion, AnimatePresence } from "framer-motion";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import TextSmall from "../components/TextSmall";
import TextItalic from "../components/TextItalic";
import TextNoPadding from "../components/TextNoPadding";

import Header from "../components/Header";
import HeaderSmall from "../components/HeaderSmall";
import HeaderBig from "../components/HeaderBig";
import HeaderBigger from "../components/HeaderBigger";
import Image from "../components/Image";
import ImgDescription from "../components/ImgDescription";

import ProjectHeadline from "../components/ProjectHeadline";
import ProjectButtonSmall from "../components/ProjectButtonSmall";
import ProjectTagSmall from "../components/ProjectTagSmall";

import ShowMoreComponent from "../components/ShowMoreComponent";

import ShowMoreButtonKitt from "../components/ShowMoreButtonKitt";
import HideProcessButtonKitt from "../components/HideProcessButtonKitt";

import ParagraphSection from "../components/ParagraphSection";
import LightBgSection from "../components/LightBgSection";
import TwoColumns from "../components/TwoColumns";
import ThreeColumns from "../components/ThreeColumns";
import ImageGrid from "../components/ImageGrid";
import ProjectFooter from "../components/ProjectFooter";
import FullBleedBlock from "../components/FullBleedBlock";

import TeamSectionLincoln from "../components/TeamSectionLincoln";
import VideoSection from "../components/VideoSection";
import Imgs from "../assets/lincoln/Imgs";

import ImageBgHiro from "../assets/projectsImages/hiro.jpg";
import ImageBgSudoku from "../assets/projectsImages/sudoku.jpg";

// PROJECT RELATED IMAGES

const ProjectKitt = ({ imgHero }) => {
  const [showMore, setShowMore] = useState(false);
  const [insideProcess, setInsideProcess] = useState(false);
  const processRef = useRef(null);

  function handleClick() {
    setShowMore(!showMore);
  }
  function handleHideProcess() {
    // scroll to the process id top

    const element = document.getElementById("process_overview");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setShowMore(false);
    }, 600); // Adjust the delay as needed
  }

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInsideProcess(true);
        } else {
          setInsideProcess(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the container
      rootMargin: "0px",
      threshold: 0.1, // Adjust the threshold as needed
    });

    if (processRef.current) {
      observer.observe(processRef.current);
    }

    return () => {
      if (processRef.current) {
        observer.unobserve(processRef.current);
      }
    };
  }, []);

  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />

      {/* CONTENT STARTS HERE */}
      <div className="relative -top-60 md:-top-[20em]">
        <div className="max-w-screen-lg mx-auto pt-8">
          {/* HEADER + PARAGRAPH */}

          <ProjectHeadline
            subtitle="Design & Guide"
            title="Crafting the Digital Assistant Experience for highly demanding
                  car owners"
          />

          <ParagraphSection>
            <Header>Project Overview</Header>
            <Text>
              Our team spearheaded the development of an in-car Virtual Personal
              Assistant (VPA) for a high-end vehicle brand. Our mission: define
              VPA's role, discover new opportunities for interactions to enhance
              the experience, and provide a toolkit for solution development
              within 12 months.
            </Text>
          </ParagraphSection>
          {/* TEAM */}
          <ParagraphSection>
            <Header>Team & My Role</Header>
            <Text>
              Leading a team of designers and freelancers, my role was to guide
              the process and onboard some of the teammates to the IDEO process.
              My biggest contribution was finding the right balance between a
              vision and a detailed design. By leveraging a wide range of
              expertise, I ensured optimal impact on the project and satisfied
              the various client's stakeholder requirements.
            </Text>
          </ParagraphSection>
          <TeamSectionLincoln />
          {/* HMW */}
          <div className="bg-[#F4F1ED] text-[#6B8786]">
            <LightBgSection>
              <h3 className="text-2xl md:text-3xl ">How Might We</h3>
              <HeaderBigger>
                create a Virtual Personal Assistant that enhances the driving
                experience and meets the high expectations of owners?
              </HeaderBigger>
            </LightBgSection>
          </div>

          {/* PARAGRAPH & IMAGE */}
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Conversational Experiences</Header>
            <Text>
              The Virtual Personal Assistant helps drivers and passengers make
              informed decisions on the go, ensuring an enjoyable trip for
              everyone. To enhance safety, users can interact with the assistant
              via voice or visual interfaces based on their preferences.
            </Text>
          </ParagraphSection>

          <VideoSection url="https://youtu.be/tXAIJIafmJ0" />
          <ImgDescription>
            <span className="relative -top-8">
              The assistant provides restaurant recommendation and assists with
              backseat control.
            </span>
          </ImgDescription>
          <ImageGrid
            img1={Imgs.Img18}
            img2={Imgs.Img19}
            img1_placeholder={Imgs.Img18_placeholder}
            img2_placeholder={Imgs.Img19_placeholder}
            img1_desc="Conversation between the driver and the assistant"
            img2_desc="VPA resurfaces critical information from the restaurant info"
          />
          <Image
            src={Imgs.Img11B}
            placeholder={Imgs.Img11B_placeholder}
            desc="Conversation and interface for one journey's moment"
          />

          <VideoSection url="https://youtu.be/vp8zmFqhvrY" />
          <ImgDescription>
            <span className="relative -top-8">
              We developed a voice and touch controlled prototypes with ProtoPie
            </span>
          </ImgDescription>

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>User journey and building trust</Header>
            <Text>
              We developed the user journey established on 3 relationship
              stages, illustrating how growing trust positively impacts owning a
              client's vehicle. The Journey of Trust helped to inspire clients
              and provide them with a greater understanding of the potential of
              the solution.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img10}
            placeholder={Imgs.Img10_placeholder}
            desc="The user journey with progressively growing trust and impact"
          />
          <ParagraphSection>
            <Header>Privacy and customization of assistant's behavior</Header>
            <Text>
              Car owners were concerned about the privacy of their data. The app
              addresses these concerns by letting car owners share information
              only from preferred areas. It also allows users to personalize
              their experience by customizing modes and behavior of the
              assistant.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img17}
            placeholder={Imgs.Img17_placeholder}
            desc="Highlights from the car app focused on VPA"
          />
        </div>
        <div className="mt-10 bg-[#02282D] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Process Overview</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>Build to think</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    To quickly understand challenges, we built prototypes and
                    used Wizard of Oz methods to communicate concepts and
                    uncover real-life user issues.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Hypothesis evaluation</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    We used hypothesis to drive design decisions and narrow down
                    VPA’s roles amongst different focus areas.
                  </span>
                </TextNoPadding>
              </div>
              <div id="process_overview" className="pb-10">
                <Header>2 Rounds of testing with users</Header>
                <TextNoPadding>
                  <span className="-pt-4 text-gray-100 opacity-70">
                    It helped to increase depth and thoughtfulness of
                    experiences. User input provided additional insights into
                    VPA’s behavior framework.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>
        <div id="process" ref={processRef}>
          <Process showMore={showMore} />
          <div id="hide_process" className="h-5">
            <AnimatePresence>
              {showMore && insideProcess && (
                <HideProcessButtonKitt
                  handleClick={handleHideProcess}
                  bgColor="4D7D7B"
                  textColor="F4F1EC"
                />
              )}
            </AnimatePresence>
          </div>
        </div>
        {!showMore && (
          <div className="relative -top-60 bg-gradient-to-t from-white to-transparent">
            <ShowMoreComponent>
              <ShowMoreButtonKitt handleClick={handleClick} />
            </ShowMoreComponent>
          </div>
        )}
        {/* FULL BLEED BLOCK - SPECS & DELIVERABLES */}
        <div className="mt-10 bg-[#02282D] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Strategy & Guidelines</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>VPA's value proposition</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    Defines the VPA and highlights its role in enhancing the
                    driving experience, transforming how users perceive their
                    vehicle. This forward-looking vision focuses on users'
                    needs, and their expectations towards the modern assistant
                    experiences.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Design Guidelines</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    Focuses on the overarching principles that should lead the
                    execution process. Helps to define appropriate tonality and
                    behavior, and ground the design solution with concrete
                    examples and rules.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>

        <div className="max-w-screen-lg mx-auto bg-white pt-8">
          <Image
            src={Imgs.Img06}
            placeholder={Imgs.Img06_placeholder}
            desc="Virtual Personal Assistant Value Proposition"
          />
          <Image
            src={Imgs.Img07}
            placeholder={Imgs.Img07_placeholder}
            desc="Design shifts"
          />

          <Image
            src={Imgs.Img13}
            placeholder={Imgs.Img13_placeholder}
            desc="Guidelines: Factors that influence VPA's responses"
          />
          <Image
            src={Imgs.Img14}
            placeholder={Imgs.Img14_placeholder}
            desc="Guidelines: Conversation blocks"
          />
          <Image
            src={Imgs.Img15}
            placeholder={Imgs.Img15_placeholder}
            desc="Guidelines: Speech and visuals guidelines"
          />
          <Image
            src={Imgs.Img16}
            placeholder={Imgs.Img16_placeholder}
            desc="Guidelines: A conversation principle"
          />
          <ParagraphSection>
            <Header>Storytelling for different stakeholders</Header>
            <Text>
              During our final presentation, we demonstrated working prototypes
              to immerse our audience in the conversational experiences.
              Furthermore, we constructed a narrative that addressed various
              audience needs and requirements. Each chapter of our presentation
              addressed different facets of launching a successful solution.
            </Text>
          </ParagraphSection>
          <VideoSection url="https://youtu.be/lo54kLLuFVs" />
          {/* IN OTHERS EYES */}
          <div className="bg-[#F4F1ED] text-[#6B8786]">
            <LightBgSection>
              <HeaderBig>In my collaborators' eyes</HeaderBig>
              <TwoColumns>
                <div className="pt-8 md:pt-6">
                  <TextItalic>
                    You take great responsibility in the role of Project Lead.
                    You have the grit to get through the hard parts of the
                    content. You showcased an awareness of the finish line for
                    the project we worked on and your structure and rigor
                    definitely contribute to this. Overall you are content
                    driven and that is often the most authentic way to inspire
                    and drive clients and teams.
                  </TextItalic>
                  <TextSmall>- Remy Jauffret, Design Director</TextSmall>
                </div>
                <div className="pt-2 md:pt-6">
                  <TextItalic>
                    For me, your biggest contribution was the way you empower
                    our team to deliver high quality deliverables through a
                    structured framework and directions and move forward with
                    efficiency.
                  </TextItalic>
                  <TextSmall>- Junchao Lv, Interaction Designer</TextSmall>
                </div>
              </TwoColumns>
            </LightBgSection>
          </div>
          {/* REFLECTIONS*/}
          <div className="bg-[#F4F1ED] text-[#6B8786]">
            <LightBgSection>
              <HeaderBig>My Learnings & Reflections</HeaderBig>
              <ThreeColumns>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>Balancing opposing needs</HeaderSmall>
                  <TextSmall>
                    From the beginning, we realized the difference in
                    expectations from the client’s stakeholders. Senior leaders
                    looked for an inspiring vision that differentiates them from
                    competitors while the execution team looked for guidance and
                    support to start the development. Dividing the deliverables
                    with three different audiences in mind allowed us to stay
                    focused and deeply empathize with the needs of each group.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>
                    Knowing the tech capabilities sooner
                  </HeaderSmall>
                  <TextSmall>
                    After the project ended, we reach out to the client to learn
                    more about the progress. The scale of our client doesn’t
                    allow them to influence their tech provider for needed
                    customization. In many cases, they had to use off-the-shelf
                    experiences. If we had known these constraints we would
                    create solution based on the tech provider system but their
                    final business agreement was finalized after the project
                    ended.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>Recent advancement in LMMs</HeaderSmall>
                  <TextSmall>
                    Our approach was heavily based on the manual creation of the
                    conversations and a decision tree approach as this project
                    happen before the explosion of innovation in the space of
                    LMM. Today, I think our deliverables would be focused on
                    recommending the right path to train a model to achieve
                    brand tonality and find the right opportunities to retrain
                    the model after user feedback.
                  </TextSmall>
                </div>
              </ThreeColumns>
            </LightBgSection>
          </div>
        </div>
      </div>
      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/stem/`}>
          <ProjectButtonSmall src={ImageBgHiro}>
            <ProjectTagSmall>Design & Explore</ProjectTagSmall>
            <HeaderBig>
              Designing a development program for STEM Teachers in the
              Philippines
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/sudoku/`}>
          <ProjectButtonSmall src={ImageBgSudoku}>
            <ProjectTagSmall>Design & Have Fun</ProjectTagSmall>
            <HeaderBig>
              Developing an iOS sudoku game with more playfulness
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
      </ProjectFooter>
    </div>
  );
};
function Process(showMore) {
  return (
    <AnimatePresence>
      <motion.div
        className={`max-w-screen-lg mx-auto bg-white pt-8  ${
          !showMore.showMore &&
          "opacity-20 h-[32rem] overflow-hidden transition-all"
        }`}
      >
        <ParagraphSection>
          <Header>Interviews and testing sessions</Header>
          <Text>
            The team engaged with target customers, including owners of client
            vehicles and owners of competitors' vehicles to diversify
            perspectives. They participated in design iterations, providing
            in-depth interviews and feedback, and helped us to understand their
            values, needs, and expectations towards Virtual Personal Assistants.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img01}
          placeholder={Imgs.Img01_placeholder}
          desc="Interviews Highlights"
        />
        {/* PARAGRAPH & IMAGE */}
        {showMore.showMore && (
          <div>
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>Building fast to learn fast </Header>
              <Text>
                To quickly immerse ourselves in the challenge, I encouraged the
                team to bring tangible materials to the users from the get-go.
                We established an internal process to build conversations
                between Luna and drivers to have more actionable discussions as
                a team. We built a simple car frame and one of us became the
                voice of a personal assistant and called from another room
                during the tests.
              </Text>
            </ParagraphSection>
            <VideoSection url="https://www.youtube.com/watch?v=J33LHwUXIfE" />
            <Image
              src={Imgs.Img02}
              placeholder={Imgs.Img02_placeholder}
              desc="Built car frame with prototypes and first iteration of the experience"
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>
                <span className="opacity-50">Testing Round 1:</span> Testing
                through hypotheses
              </Header>
              <Text>
                The first round aimed to confirm or disprove hunches from
                initial research and client discussions, swiftly narrowing down
                the assistant's role and collecting rich feedback for further
                concept development.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img03}
              placeholder={Imgs.Img03_placeholder}
              desc="Hypotheses evaluated during the testing"
            />
            {/* PARAGRAPH & IMAGE */}
            <ParagraphSection>
              <Header>
                <span className="opacity-50">Testing Round 2:</span>{" "}
                Experience-driven testing
              </Header>
              <Text>
                In the second round, we enhanced prototype fidelity, aligning
                visuals with the brand and offering nuanced conversations. This
                garnered detailed user feedback and shaped a framework for the
                assistant's behavior.
              </Text>
            </ParagraphSection>
            <Image
              src={Imgs.Img04}
              placeholder={Imgs.Img04_placeholder}
              desc="Second iteration of experience - conversation and interface design"
            />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectKitt;
