import { motion, AnimatePresence } from "framer-motion";

export default function ProjectButton({ src, children }) {
  return (
    <AnimatePresence>
      <motion.div
        className="h-[30em] md:h-[40em] font-bold text-white p-8 md:p-28  bg-no-repeat bg-bottom bg-cover bg-opacity-60"
        style={{ backgroundImage: `url(${src})` }}
        initial={{
          opacity: 0,
          y: 10,
          boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.0)",
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.9 }}
        exit={{ opacity: 0 }}
        // Interaction
        whileHover={{
          scale: 1.09,
          boxShadow: "10px 10px 75px rgba(0, 0, 0, 0.2)",
          transition: { ease: "anticipate", duration: 0.6 },
        }}
      >
        <motion.div className="relative top-40 lg:top-48">
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
