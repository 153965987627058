import { ArrowSmallUpIcon } from "@heroicons/react/24/outline";

export default function GoUp() {
  return (
    <div>
      <a href="#top">
        <div className="text-neutral-500 underline text-right flex gap-2">
          <p>
            <span className="hidden md:inline">Go </span>Up
          </p>
          <ArrowSmallUpIcon
            className="w-4 h-4 md:h-6 md:w-6"
            aria-hidden="true"
          />
        </div>
      </a>
    </div>
  );
}
