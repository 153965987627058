import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";

export default function HideProcessButton({ handleClick }) {
  return (
    <motion.button onClick={() => handleClick()}>
      <motion.div
        className={
          "bg-[#60A5FA] text-white  fixed top-20 right-4 lg:right-3 xl:right-16 2xl:right-44 hidden md:flex rounded-m text-xs text-center flex items-center gap-2 px-4 py-4 my-0 shadow-2xl"
        }
        whileHover={{
          scale: 0.9,
          transition: { ease: "anticipate", duration: 0.6 },
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3 }}
        exit={{ opacity: 0, x: 0 }}
      >
        <motion.p className="hidden lg:flex">Hide Process</motion.p>
        <ArrowUpIcon className="h-4 w-4" aria-hidden="true" />
      </motion.div>
    </motion.button>
  );
}
