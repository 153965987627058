import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import TextSmall from "../components/TextSmall";
import TextItalic from "../components/TextItalic";
import TextNoPadding from "../components/TextNoPadding";
import Header from "../components/Header";
import HeaderSmall from "../components/HeaderSmall";
import HeaderBig from "../components/HeaderBig";
import HeaderBigger from "../components/HeaderBigger";
import Image from "../components/Image";
import ImgDescription from "../components/ImgDescription";
import ImageGrid from "../components/ImageGrid";

import ProjectButtonSmall from "../components/ProjectButtonSmall";
import ProjectHeadline from "../components/ProjectHeadline";
import ProjectTagSmall from "../components/ProjectTagSmall";

import ShowMoreComponent from "../components/ShowMoreComponent";

import ShowMoreButtonGBF from "../components/ShowMoreButtonGBF";
import HideProcessButton from "../components/HideProcessButtonGBF";

import ParagraphSection from "../components/ParagraphSection";
import LightBgSection from "../components/LightBgSection";
import TwoColumns from "../components/TwoColumns";
import ProjectFooter from "../components/ProjectFooter";
import FullBleedBlock from "../components/FullBleedBlock";

import ImageBgLandd from "../assets/projectsImages/landd.jpg";
import ImageBgSudoku from "../assets/projectsImages/sudoku.jpg";
import TeamSectionHiro from "../components/TeamSectionHiro";
import VideoSection from "../components/VideoSection";
import Imgs from "../assets/hiro/Imgs";
// PROJECT RELATED IMAGES

const ProjectGBF = ({ imgHero }) => {
  const [showMore, setShowMore] = useState(false);
  const [insideProcess, setInsideProcess] = useState(false);
  const processRef = useRef(null);

  function handleClick() {
    setShowMore(!showMore);
  }
  function handleHideProcess() {
    // scroll to the process id top

    const element = document.getElementById("process_overview");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setShowMore(false);
    }, 600); // Adjust the delay as needed
  }

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInsideProcess(true);
        } else {
          setInsideProcess(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the container
      rootMargin: "0px",
      threshold: 0.1, // Adjust the threshold as needed
    });

    if (processRef.current) {
      observer.observe(processRef.current);
    }

    return () => {
      if (processRef.current) {
        observer.unobserve(processRef.current);
      }
    };
  }, []);
  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />
      <div className="relative -top-60 md:-top-[20em]">
        {/* CONTENT STARTS HERE */}
        <div className="max-w-screen-lg mx-auto pt-8">
          {/* HEADER + PARAGRAPH */}
          <ProjectHeadline
            subtitle="Design & Explore"
            title="Designing a development program for STEM Teachers in the Philippines"
          />
          <ParagraphSection>
            <Header>Project Overview</Header>
            <Text>
              Our team collaborated with a pro bono education organization to
              develop the foundation of the educators' program for Philippines
              STEM teachers. Rooted in insights from research conducted with
              educators, students, and expert advisors, our program aims to
              ignite visible change in STEM education. It empowers educators to
              pass the knowledge and passion to cultivate the next generation of
              engineers, scientists, leaders, and entrepreneurs.
            </Text>
          </ParagraphSection>
          {/* TEAM */}
          <ParagraphSection>
            <Header>Team & My Role</Header>
            <Text>
              Together with a design researcher and visual designer from IDEO
              Tokyo, I led our design process and ensured high-quality content
              delivery on a tight schedule. I was also responsible for managing
              the collaboration with a research partner that locally executed
              our research. My key contribution was keeping the team focused and
              aligned amid multiple directions, pivots, and various external
              inputs.
            </Text>
          </ParagraphSection>
          <TeamSectionHiro />
          {/* HMW */}
          <div className="bg-blue-100 text-blue-600">
            <LightBgSection>
              <h3 className="text-2xl md:text-3xl ">How Might We</h3>
              <HeaderBigger>
                design a flagship program for STEM educator development that
                makes a tangible impact on students, in a scalable and
                sustainable way?
              </HeaderBigger>
            </LightBgSection>
          </div>
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>
              Bite-size modules are designed to fit into teachers' busy lives.
            </Header>
            <Text>
              Teachers often need to improve their STEM foundational knowledge
              or refresh forgotten concepts. Bite-sized modules, each under 15
              minutes, fit easily into busy schedules. These modules cover both
              content and teaching methods, with personalized recommendations
              tailored to each teacher's needs.
            </Text>
          </ParagraphSection>
          <VideoSection url="https://youtu.be/2oyhoYoKhJ8" />
          <ImgDescription>
            <span className="relative -top-8">
              For every class teacher could complete teaching quizes and learn
              new teaching strategies.
            </span>
          </ImgDescription>
          <VideoSection url="https://youtu.be/KFnS6lOASQQ" />
          <ImgDescription>
            <span className="relative -top-8">
              Bite-size modules are presented in a visually engaging way to make
              learning enjoyable and alleviate teachers' daily pressures.
            </span>
          </ImgDescription>
          <ParagraphSection>
            <Header>Class Builder with ready to use teaching resources</Header>
            <Text>
              Teachers often bring work home, assessing tests and homework while
              preparing materials for the next day's classes. Class Builder
              offers a guided walkthrough, providing teaching resources and
              learning moments, helping teachers prepare effectively for their
              classes and build their knowledge at the same time.
            </Text>
          </ParagraphSection>
          <VideoSection url="https://youtu.be/rdaL4tmVio0" />
          <ImgDescription>
            <span className="relative -top-8">
              Reviewing the materials and understanding the rationale behind
              them helps teachers improve their teaching methods.
            </span>
          </ImgDescription>
          <Image
            src={Imgs.Img23}
            placeholder={Imgs.Img23_placeholder}
            desc="All teaching materials are downloadable and immediately ready for use in class."
          />
          <ParagraphSection>
            <Header>Learning and improving with teachers' community</Header>
            <Text>
              Being a teacher can feel lonely, especially without like-minded
              colleagues. However, teachers are eager to share their knowledge.
              Learning in a community helps teachers connect, get support from
              experienced peers, ask questions, and receive advice on classroom
              challenges.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img16}
            placeholder={Imgs.Img16_placeholder}
            desc="Teachers can get one-on-one coaching from experienced educators and explore specialized groups."
          />
          <ImageGrid
            img1={Imgs.Img17}
            img2={Imgs.Img18}
            img1_placeholder={Imgs.Img17_placeholder}
            img2_placeholder={Imgs.Img18_placeholder}
            img1_desc="Teachers join group coaching tailored to their needs."
            img2_desc="The sessions offer interactive learning moments."
          />
          <ParagraphSection>
            <Header>The program in the everyday life of a teacher</Header>
            <Text>
              We crafted a teacher's journey, illustrating how program features
              align with teachers' daily routines and enhance their teaching
              skills. This journey provides clarity on how teachers will use,
              benefit, and progressively engage with the program.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img19}
            placeholder={Imgs.Img19_placeholder}
            desc="Teacher Anna gains confidence and receives valuable help through the app."
          />
          <ParagraphSection>
            <Header>Information Architecture & Design System</Header>
            <Text>
              The team developed a design system to ensure consistency across
              the brand's visual and functional elements, enhancing user
              experience and trust. This system also accelerates development by
              providing reusable components and guidelines, streamlining the
              design and implementation process.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img20}
            placeholder={Imgs.Img20_placeholder}
            desc="Information Architecture"
          />
          <Image
            src={Imgs.Img21}
            placeholder={Imgs.Img21_placeholder}
            desc="Typography & Colors system"
          />
          <Image
            src={Imgs.Img22}
            placeholder={Imgs.Img22_placeholder}
            desc="Major components & illustration styling"
          />
        </div>
        <div className="mt-10 bg-[#2563EB] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Process Overview</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>Understanding educational system</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    We conducted thorough research to understand the structure,
                    challenges, and needs of the Philippines educational system.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Prototyping week with teachers</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    We organized a week-long prototyping session with teachers
                    to gather practical insights and refine our ideas.
                  </span>
                </TextNoPadding>
              </div>
              <div id="process_overview" className="pb-10">
                <Header>Involving experts</Header>
                <TextNoPadding>
                  <span className="-pt-4 text-gray-100 opacity-70">
                    We involved educational experts to provide valuable feedback
                    and ensure our solutions are grounded in best practices.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>

        <div id="process" ref={processRef}>
          <Process showMore={showMore} />
          <div id="hide_process" className="h-5 text-gray-100">
            <AnimatePresence>
              {showMore && insideProcess && (
                <HideProcessButton handleClick={handleHideProcess} />
              )}
            </AnimatePresence>
          </div>
        </div>
        {!showMore && (
          <div className="relative -top-60 bg-gradient-to-t from-white to-transparent">
            <ShowMoreComponent>
              <ShowMoreButtonGBF handleClick={handleClick} />
            </ShowMoreComponent>
          </div>
        )}
        {/* FULL BLEED BLOCK - SPECS & DELIVERABLES */}
        <div className="mt-10 bg-[#2563EB] text-gray-100">
          <FullBleedBlock>
            <div className="md:w-1/3 opacity-90 pb-6 md:pb-12 md:relative md:-top-9">
              <HeaderBigger>Org. Framework</HeaderBigger>
            </div>
            <div className="md:pl-24 opacity-90">
              <div className="pb-10">
                <Header>Learning Model</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60 ">
                    The learning model tailors content to individual teachers,
                    enhancing their experience with personalized pathways that
                    match their pace and proficiency, making the app more
                    effective.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>Tools for community building</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    Tools help to build a strong community to enable teachers to
                    learn from each other, stay motivated, and continuously
                    improve their teaching methods.
                  </span>
                </TextNoPadding>
              </div>
              <div className="pb-10">
                <Header>KPIs to measure the success</Header>
                <TextNoPadding>
                  <span className="text-gray-100 opacity-60">
                    Captures various aspects such as teacher retention and
                    engagement in development activities. Assesses the program's
                    impact, and showcase areas for improvement.
                  </span>
                </TextNoPadding>
              </div>
            </div>
          </FullBleedBlock>
        </div>
        <div className="max-w-screen-lg mx-auto bg-white pt-8">
          <Image
            src={Imgs.Img13}
            placeholder={Imgs.Img13_placeholder}
            desc="The model highlights three types of activities, consisting of self-learning and shared learning"
          />
          <Image
            src={Imgs.Img12}
            placeholder={Imgs.Img12_placeholder}
            desc="The toolkit groups teachers into three roles and suggests various types of engagements."
          />

          <Image
            src={Imgs.Img14}
            placeholder={Imgs.Img14_placeholder}
            desc="Measuring the success of the program involves using progress indicators and evaluation tools. "
          />

          <VideoSection url="https://youtu.be/BzFK7wptM0M" />
          <ImgDescription>
            <span className="relative -top-8">
              Summary of our process and program's key features and benefits
            </span>
          </ImgDescription>

          {/* IN OTHERS EYES */}
          <div className="bg-blue-100 text-blue-600">
            <LightBgSection>
              <HeaderBig>In my collaborators' eyes</HeaderBig>
              <TwoColumns>
                <div className="pt-8 md:pt-6">
                  <TextItalic>
                    Piotr's greatest contribution to the team is how he can
                    drive people to take action, even amidst ambiguity. I
                    sometimes felt a slight paralysis facing the immense amount
                    of questions and information we had to handle. However,
                    Piotr would always have an idea of how we could approach the
                    challenge and a hypothesis of what we might want to create
                    that kept us constantly moving. I really appreciated his
                    spark and drive in those moments.
                  </TextItalic>
                  <TextSmall>- Yuriko Yamaguchi, Design Researcher</TextSmall>
                </div>
                <div className="pt-2 md:pt-6">
                  <TextItalic>
                    Piotr's leadership really shines when he creates structure
                    in all the chaos, helping the team make sense of all the
                    factors in motion such as logistics, clients, stakeholders,
                    project objectives, and working together as team. When we
                    felt lost at times, he helped make sense of the ambiguity by
                    simplifying the complexity and creating structure as a means
                    of progressing forward.
                  </TextItalic>
                  <TextSmall>
                    - Dingo Francisco, Communication Designer
                  </TextSmall>
                </div>
              </TwoColumns>
            </LightBgSection>
          </div>
          {/* REFLECTIONS*/}
          <div className="bg-blue-100 text-blue-600">
            <LightBgSection>
              <HeaderBig>My Learnings & Reflections</HeaderBig>
              <TwoColumns>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>
                    Building trust with the project sponsor
                  </HeaderSmall>
                  <TextSmall>
                    This project taught me the importance of establishing trust
                    with the key sponsor. Initially facing skepticism about my
                    contributions and methods, I successfully built trust,
                    ensuring a smooth collaboration. Learning from this, I now
                    prioritize regular 1:1 chats with key stakeholders before
                    and during a project, fostering understanding and mutual
                    trust.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>
                    Not enough bandwidth to respond to the feedback
                  </HeaderSmall>
                  <TextSmall>
                    Handling multiple stakeholder groups led to packed weeks of
                    consecutive feedback sessions, limiting our ability to
                    respond effectively. Today, I would revise the project plan
                    to include morning feedback sessions and dedicate afternoons
                    to implementing improvements. I'd also streamline the list
                    of experts for each group, valuing depth over breadth in
                    perspectives for more impactful results.
                  </TextSmall>
                </div>
              </TwoColumns>
            </LightBgSection>
          </div>
        </div>
      </div>

      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/sudoku/`}>
          <ProjectButtonSmall src={ImageBgSudoku}>
            <ProjectTagSmall>Design & Have Fun</ProjectTagSmall>
            <HeaderBig>
              Developing an iOS sudoku game with more playfulness
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/development/`}>
          <ProjectButtonSmall src={ImageBgLandd}>
            <ProjectTagSmall>Summary of</ProjectTagSmall>
            <HeaderBig>
              My involvement in Learning & Development at IDEO
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
      </ProjectFooter>
    </div>
  );
};

function Process(showMore) {
  return (
    <AnimatePresence>
      <motion.div
        className={`max-w-screen-lg mx-auto bg-white pt-8  ${
          !showMore.showMore &&
          "opacity-20 h-[32rem] overflow-hidden transition-all"
        }`}
      >
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>Research approach</Header>
          <Text>
            Our initial focus was on understanding teachers' challenges and
            their impact on students. Exploring struggles in and beyond the
            classroom, we sought insights into content knowledge, pedagogy, and
            ways to enhance interactions with students. Engaging kids, teachers,
            and educational institutions, our research provided a comprehensive
            overview.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img01}
          placeholder={Imgs.Img01_placeholder}
          desc="Research snapshot"
        />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>Involving education experts </Header>
          <Text>
            Discovering an article aligning with our brief on Philippines K-12
            STEM education, I invited the author, Louie, to join the project.
            Throughout, he enriched our understanding of teachers' problems,
            shared knowledge of previous initiatives, and provided valuable
            feedback. The successful collaboration resulted in the inclusion of
            another expert, Karen, who played a key role in developing content
            for the prototypes.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img02}
          placeholder={Imgs.Img02_placeholder}
          desc="Experts involved in the project"
        />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>Adding tangibility to potential future</Header>
          <Text>
            In interviews, we presented sacrificial concepts spanning community,
            knowledge assessment, and student relationships. Encouraging
            simplicity for effective communication, our fidelity allowed
            interviewees to grasp concepts while leaving room for interpretation
            and building upon them.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img03}
          placeholder={Imgs.Img03_placeholder}
          desc="Sacrificial concepts evaluated with teachers"
        />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>Engaging teachers during prototyping weeks</Header>
          <Text>
            Inviting 9 teachers to a dedicated Facebook group, we shared weekly
            plans, prototype explanations, and tasks aligned with their school
            progress. This hands-on approach allowed the immediate application
            of learnings. Closing interviews at the end of each week gathered
            valuable teacher feedback.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img04}
          placeholder={Imgs.Img04_placeholder}
          desc="Group activities conducted on Facebook group"
        />

        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            Involving external stakeholders for credibility and buy-in
          </Header>
          <Text>
            To ensure comprehensive feedback, we formed two groups for iterative
            feedback sessions instead of proposing solutions in isolation. The
            Advisory Council, comprising 9 education sector experts, and the
            Partner Consortium, a group of potential implementation partners,
            ensured diverse perspectives from schools, government agencies,
            startups, and technology, content, community, and teacher training
            sectors.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img05}
          placeholder={Imgs.Img05_placeholder}
          desc="External advisors"
        />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>Inspiring a change through design shifts</Header>
          <Text>
            To offer an overview of the learnings from the research and
            prototyping phase of the project, we identified design shifts that
            we incorporated into the final solution.
          </Text>
        </ParagraphSection>
        <Image
          src={Imgs.Img06}
          placeholder={Imgs.Img06_placeholder}
          desc="Design shifts based on research findings"
        />
        {/* PARAGRAPH & IMAGE */}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProjectGBF;
