import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ImgDescription from "./ImgDescription";

export default function TallImageWider({ src, placeholder, desc, wider }) {
  return (
    <div className="pb-2 pt-0 px-6 lg:px-20 min-h-[150px]">
      <LazyLoadImage
        alt={desc ? desc : "Portfolio Img"}
        effect="blur"
        threshold="500"
        src={src}
        placeholderSrc={placeholder}
      />
      <ImgDescription>{desc}</ImgDescription>
    </div>
  );
}
