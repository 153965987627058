import { motion, AnimatePresence } from "framer-motion";

import HeaderBigger from "../components/HeaderBigger";
import HeaderBiggest from "../components/HeaderBiggest";
import ProjectTagSmall from "../components/ProjectTagSmall";

export default function ProjectHeadline({ subtitle, title, textColor }) {
  return (
    <AnimatePresence>
      <motion.div
        className={`z-10 mx-auto px-8 md:px-20 pt-14 pb-0 text-[#${textColor}]`}
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <ProjectTagSmall>{subtitle}</ProjectTagSmall>
        <HeaderBiggest>{title}</HeaderBiggest>
      </motion.div>
    </AnimatePresence>
  );
}
