import { motion, AnimatePresence } from "framer-motion";

export default function HeroImage({ src }) {
  return (
    <AnimatePresence>
      <motion.div
        className="-z-50 h-[30em] md:h-[40em] lg:h-[50em] xl:[64em] bg-no-repeat bg-bottom bg-cover"
        style={{ backgroundImage: `url(${src})` }}
        initial={{ scale: 1.1, y: 40 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0 }}
      />
    </AnimatePresence>
  );
}
