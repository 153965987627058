import { HashLink as Link } from "react-router-hash-link";

import Header from "../components/Header";
import TwoColumnsProjects from "../components/TwoColumnsProjects";

import GoUp from "./GoUp";

export default function ProjectFooter({ children }) {
  return (
    <div>
      <div className="bg-gray-200/70 p-8 md:p-20 ">
        <div className="max-w-screen-xl mx-auto">
          {SeeMoreProjects()}
          <TwoColumnsProjects>{children}</TwoColumnsProjects>
        </div>
      </div>
      {BottomFooter()}
    </div>
  );

  function SeeMoreProjects() {
    return (
      <div className="pb-8 flex justify-between ">
        <Header>See more projects</Header>
        <div className="pt-1">
          <GoUp />
        </div>
      </div>
    );
  }

  function BottomFooter() {
    return (
      <div className="h-full w-full bg-gray-900 text-neutral-100 p-8 md:p-20">
        <div className="max-w-screen-xl mx-auto">
          <div className="text-xs md:text-base flex justify-between">
            <p>
              © 2024, <span className="hidden md:inline">Hand coded by </span>
              Piotr Kuklo
            </p>
            <div className=" text-neutral-500 underline text-right flex gap-6 md:gap-10 ">
              <Link to="/home/#projects">
                <span className="hover:text-neutral-100 leading">Projects</span>
              </Link>
              <Link to="/home/#aboutme" className="hover:text-neutral-100 ">
                About me
              </Link>
              <Link to="/home#contact" className="hover:text-neutral-100 ">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
