import ImgHero from "./hero.jpg";
import Img01 from "./01.jpg";
import Img01_placeholder from "./01_placeholder.jpg";
import Img02 from "./02.jpg";
import Img02_placeholder from "./02_placeholder.jpg";
import Img03 from "./03.jpg";
import Img03_placeholder from "./03_placeholder.jpg";
import Img04 from "./04.jpg";
import Img04_placeholder from "./04_placeholder.jpg";
import Img05 from "./05.jpg";
import Img05_placeholder from "./05_placeholder.jpg";
import Img06 from "./06.jpg";
import Img06_placeholder from "./06_placeholder.jpg";
import Img07 from "./07.jpg";
import Img07_placeholder from "./07_placeholder.jpg";
import Img08 from "./08.jpg";
import Img08_placeholder from "./08_placeholder.jpg";
import Img09 from "./09.jpg";
import Img09_placeholder from "./09_placeholder.jpg";
import Img10 from "./10.jpg";
import Img10_placeholder from "./10_placeholder.jpg";
import Img11 from "./11.jpg";
import Img11_placeholder from "./11_placeholder.jpg";
import Img12 from "./12-community.jpg";
import Img12_placeholder from "./12-community_placeholder.jpg";
import Img13 from "./12-model.jpg";
import Img13_placeholder from "./12-model_placeholder.jpg";
import Img14 from "./12-kpi.jpg";
import Img14_placeholder from "./12-kpi_placeholder.jpg";
import Img15 from "./11.jpg";
import Img15_placeholder from "./11_placeholder.jpg";

//
import Img16 from "./14-community-1.jpg";
import Img16_placeholder from "./14-community-1_placeholder.jpg";
import Img17 from "./14-community-2.jpg";
import Img17_placeholder from "./14-community-2_placeholder.jpg";
import Img18 from "./14-community-3.jpg";
import Img18_placeholder from "./14-community-3_placeholder.jpg";
import Img19 from "./13-user-journey.jpg";
import Img19_placeholder from "./13-user-journey_placeholder.jpg";
import Img20 from "./13-architecture.jpg";
import Img20_placeholder from "./13-architecture_placeholder.jpg";
import Img21 from "./13-typography.jpg";
import Img21_placeholder from "./13-typography_placeholder.jpg";
import Img22 from "./13-components.jpg";
import Img22_placeholder from "./13-components_placeholder.jpg";
import Img23 from "./14-class-builder-2.jpg";
import Img23_placeholder from "./14-class-builder-2_placeholder.jpg";

const Imgs = {
  ImgHero,
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  Img06,
  Img07,
  Img08,
  Img09,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,

  Img01_placeholder,
  Img02_placeholder,
  Img03_placeholder,
  Img04_placeholder,
  Img05_placeholder,
  Img06_placeholder,
  Img07_placeholder,
  Img08_placeholder,
  Img09_placeholder,
  Img10_placeholder,
  Img11_placeholder,
  Img12_placeholder,
  Img13_placeholder,
  Img14_placeholder,
  Img15_placeholder,
  Img16_placeholder,
  Img17_placeholder,
  Img18_placeholder,
  Img19_placeholder,
  Img20_placeholder,
  Img21_placeholder,
  Img22_placeholder,
  Img23_placeholder,
};
export default Imgs;
