import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import Header from "../components/Header";
import HeaderBig from "../components/HeaderBig";
import Image from "../components/Image";
import Gif from "../components/Gif";
import ProjectButtonSmall from "../components/ProjectButtonSmall";
import ProjectHeadline from "../components/ProjectHeadline";
import ProjectTagSmall from "../components/ProjectTagSmall";

import ParagraphSection from "../components/ParagraphSection";
import ProjectFooter from "../components/ProjectFooter";

import ImageBgNectar from "../assets/projectsImages/nectar.jpg";
import ImageBgReflektt from "../assets/projectsImages/reflektt.jpg";
import ImageBgHiro from "../assets/projectsImages/hiro.jpg";
import ImageBgLincoln from "../assets/projectsImages/lincoln.jpg";

import Imgs from "../assets/uis/Imgs";

// PROJECT RELATED IMAGES

const Project6 = ({ imgHero }) => {
  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />

      {/* CONTENT STARTS HERE */}
      <div className="relative -top-40 max-w-screen-lg mx-auto bg-white pt-8">
        {/* HEADER + PARAGRAPH */}
        {/* TO BE ADDED if it will be presented - <ProjectHeadline subtitle="" title="" /> */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">1:</span> Google Search Ads
          </Header>
          <Text>
            For half a year I was embedded at Google Ads Design Team supporting
            efforts in ads experience to make it more attractive and relevant
            for Google Search's users while keeping needs of advertisers and
            Google's revenue. I worked on multiple work stream collaborating
            with PM and Engineers.
          </Text>
        </ParagraphSection>
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Secondary Actions Framework</span>
          </Header>
          <Text>
            Developing a systemic approach with new ways of presenting the
            information and bring visual consistency to Secondary Action
            components.
          </Text>
        </ParagraphSection>

        <Image src={Imgs.Img01} placeholder={Imgs.Img01_placeholder} />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Exploration of page midsection</span>
          </Header>
          <Text>
            Exploring incorporating an ads module in the middle of the search
            result while testing the performance of removing a bottom ads
            module.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img02} placeholder={Imgs.Img02_placeholder} />

        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Price Extension</span>
          </Header>
          <Text>
            Designing the new ways of showing price extensions. The price
            extension module and its hidden content were neglected by a user.
            Its performance has been below monetary expectations for many years.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img03} placeholder={Imgs.Img03_placeholder} />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">2:</span> Smart Meeting Experience
          </Header>
          <Text>
            The project was conducted for a Chinese client based in Shanghai
            wanting to enter the smart office in corporate setup. We focused the
            efforts on prototyping a platform to increase the internal team’s
            productivity while keeping their regular behaviors. As final
            deliverables, we built the interactive experience and demonstrated a
            fully functional prototype. The deliverables helped to understand
            the client’s opportunities in the area of a smart office.
          </Text>
        </ParagraphSection>
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Meeting Preparation</span>
          </Header>
          <Text>
            The main screen helps users to manage task items that they are
            involved in, as a group or individually. The platform clusters
            specific items (based on similar participants, deadlines, or topics)
            and creates a recommendation for a meeting based on participants'
            availability.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img04} placeholder={Imgs.Img04_placeholder} />

        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Collaborating on an agenda</span>
          </Header>
          <Text>
            Before a meeting, participants can collaborate on an agenda
            asynchronously. They can create an update, share their thoughts on
            each item or have a voting poll for simple decisions.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img05} placeholder={Imgs.Img05_placeholder} />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Meeting Summary</span>
          </Header>
          <Text>
            A summary of the finished meeting helps to reflect on its content
            and its outcome. Participants see which items were covered and
            solved during the meeting, what are the next steps (with a list of
            tasks they are involved in) and get access to their annotations.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img06} placeholder={Imgs.Img06_placeholder} />
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">3:</span> A platform for pet owners and
            vets
          </Header>
          <Text>
            Our team defined the vision and strategy for a startup in the pet
            health care space. The outcome is Buddy - a digital platform that
            seamlessly connects pet owners with veterinarians 24/7 to receive
            personalized advice and guidance on their pet’s health.
          </Text>
        </ParagraphSection>
        {/* PARAGRAPH & IMAGE */}
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Symptom checker</span>
          </Header>
          <Text>
            The symptom checker lets user know if a visit is needed and how
            urgent is the issue. It also gives them support and guidance on what
            to do in the meantime. Repeated symptom tracking is captured in the
            pet’s file, so the vet can see how symptoms are changing over time.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img07} placeholder={Imgs.Img07_placeholder} />
        <ParagraphSection>
          <Header>
            <span className="opacity-50">Virtual vet visit</span>
          </Header>
          <Text>
            A vet learns about the situation based on symptom checker. The
            platform prioritizes pet owners based on the urgency of their pet’s
            symptoms. In the middle of the screen, a vet sees information about
            a patient and a template for the SOAP method to input information
            during triage.
          </Text>
        </ParagraphSection>
        <Image src={Imgs.Img08} placeholder={Imgs.Img08_placeholder} />
        <div className="bg-[#FAF1E6]">
          <Gif src={Imgs.Img09} />
        </div>
      </div>
      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/reflektt/`}>
          <ProjectButtonSmall src={ImageBgReflektt}>
            <ProjectTagSmall>Design & Launch</ProjectTagSmall>
            <HeaderBig>Building an AI tool for reflective journaling</HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/blood_testing/`}>
          <ProjectButtonSmall src={ImageBgNectar}>
            <ProjectTagSmall>Design & Detail</ProjectTagSmall>
            <HeaderBig>
              Building a blood testing device for an Emergency Room environment
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        {/* <Link to={`/assistant/`}>
          <ProjectButtonSmall src={ImageBgLincoln}>
            <ProjectTagSmall>Design & Guide</ProjectTagSmall>
            <HeaderBig>
              Defining the role of Digital Assistant for highly demanding car
              owners
            </HeaderBig>
          </ProjectButtonSmall>
        </Link> */}
      </ProjectFooter>
    </div>
  );
};

export default Project6;
