export default function Gif({ src, placeholder, desc }) {
  return (
    <div className="pb-14 pt-8 ">
      <img className="mx-auto" alt={desc} src={src} />
      <div className="text-center">
        <p className="text-sm opacity-60">{desc}</p>
      </div>
    </div>
  );
}
