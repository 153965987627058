import React from "react";
import { useState, useEffect } from "react";

import ReactPlayer from "react-player";

export default function VideoSection({ url, wider = false }) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    let value = (1024 / 16) * 9;
    if (window.innerWidth < 1024) {
      value = (window.innerWidth / 16) * 9;
    }
    return {
      width: value,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div className="w-full py-10">
      <div className="mx-auto">
        <ReactPlayer
          width="100%"
          height={screenSize.width}
          url={url}
          playing="true"
          loop="true"
          volume="0"
        ></ReactPlayer>
      </div>
    </div>
  );
}
