import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import TextSmall from "../components/TextSmall";
import TextItalic from "../components/TextItalic";
import Header from "../components/Header";
import HeaderSmall from "../components/HeaderSmall";
import HeaderBig from "../components/HeaderBig";
import HeaderBigger from "../components/HeaderBigger";
import Image from "../components/Image";
import ProjectButtonSmall from "../components/ProjectButtonSmall";

import ProjectHeadline from "../components/ProjectHeadline";
import ProjectTagSmall from "../components/ProjectTagSmall";
import ImageGrid from "../components/ImageGrid";

import ParagraphSection from "../components/ParagraphSection";
import LightBgSection from "../components/LightBgSection";
import TwoColumns from "../components/TwoColumns";
import ThreeColumns from "../components/ThreeColumns";
import ProjectFooter from "../components/ProjectFooter";

import ImageBgNectar from "../assets/projectsImages/nectar.jpg";
import ImageBgLincoln from "../assets/projectsImages/lincoln.jpg";
import TeamSectionAkar from "../components/TeamSectionAkar";
import VideoSection from "../components/VideoSection";
import Imgs from "../assets/akar/Imgs";
// PROJECT RELATED IMAGES

const ProjectAkar = ({ imgHero }) => {
  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />
      <div className="relative -top-60 md:-top-[20em]">
        {/* CONTENT STARTS HERE */}
        <div className="max-w-screen-lg mx-auto pt-8">
          {/* HEADER + PARAGRAPH */}
          <ProjectHeadline
            subtitle="Research & Define"
            title="Bringing human lens to increase safety at palm oil plantations"
          />
          <ParagraphSection>
            <Header>Project Overview</Header>
            <Text>
              Our team was tasked with enhancing the safety of field workers
              during palm fruit harvesting. Despite existing industry-leading
              safety practices, the client sought to eliminate any worker
              injuries and fatalities, noting incremental improvements from
              top-down safety initiatives insufficient.
              <br />
              <br />
              <span className="font-bold">
                The project aimed to uncover key challenges in improving safety
                during harvesting and define design briefs for future projects.
              </span>
            </Text>
          </ParagraphSection>
          {/* TEAM */}
          <ParagraphSection>
            <Header>Team & My Role</Header>
            <Text>
              The core team consisted of an IDEO researcher and a local
              researcher who provided insights into Indonesian culture. Our
              extended team included a video maker, a local interpreter, and a
              visual communication designer. As Project Lead, my role involved
              overseeing the entire process, guiding the team to deliver
              insightful findings, identifying actionable opportunity areas, and
              ensuring a cohesive story aligned with stakeholders' expectations.
            </Text>
          </ParagraphSection>
          <TeamSectionAkar />
          {/* HMW */}
          <div className="bg-orange-50 text-emerald-600">
            <LightBgSection>
              <h3 className="text-2xl md:text-3xl ">How Might We</h3>
              <HeaderBigger>
                continue to improve worker safety through human-centered design?
              </HeaderBigger>
            </LightBgSection>
          </div>
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Approach to research</Header>
            <Text>
              We adopted a research framework centered on behavioral change and
              habit development, leveraging three lenses—norms, tools, and
              culture. The addition of the "life outside of work" lens
              emphasized external motivators influencing workers' safety
              attitudes. This framework ensured a broad research scope and
              clarity of objectives for stakeholders.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img01}
            placeholder={Imgs.Img01_placeholder}
            desc="Research's focus points"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Preparation for the research</Header>
            <Text>
              I guided the crafting of a research plan for diverse perspectives
              within our tight timeline. Clarifying interview purposes and
              focusing on critical questions streamlined the development of
              discussion guides, fostering creativity in research activities.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img02}
            placeholder={Imgs.Img02_placeholder}
            desc="Materials for the research"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Collaborating with external creatives</Header>
            <Text>
              To elevate our deliverables, we enlisted external talent—a video
              maker and visual designer. Collaborating closely, I provided
              briefs for mutual alignment, allowing creative freedom while
              meeting project objectives. Their contributions helped to
              captivate the audience and gain more empathy toward the lives of
              field workers.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img03}
            placeholder={Imgs.Img03_placeholder}
            desc="Briefs developed for the external creatives"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Immersion</Header>
            <Text>
              ​Our research spanned all steps of the harvesting process - tree
              clear-up, loose fruit picking, harvesting, and transportation
              stages. Using diverse methods—observation, in-depth interviews,
              and workshops with workers—we gained comprehensive insights into
              the life of harvesters.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img04}
            placeholder={Imgs.Img04_placeholder}
            desc="Research snapshot"
          />
          <VideoSection url="https://youtu.be/3h5-xIwjEz0" />

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>
              Pivoting the research to learn more about supervisors
            </Header>
            <Text>
              Midway through the research, we noticed emerging patterns,
              highlighting the influential role of supervisors in workers'
              safety. Supervisors lead a group of 16 field workers and are the
              link between the management with field workers. Their role is to
              educate and maintain the team's safety but unfortunately, they are
              not skilled enough to lead and develop their teams. Sacrificing
              some drivers' interviews, we explored the supervisory role,
              sensing an opportunity.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img05}
            placeholder={Imgs.Img05_placeholder}
            desc="The hierarchy at the plantation"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Improving safety through a culture change</Header>
            <Text>
              Plantation culture emerged as the primary obstacle to safety
              improvement. Workers felt unequal, and unheard, and faced
              misaligned safety and productivity objectives. To address this, we
              identified three opportunity areas for improvement that will
              result in increasing workers' safety.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img06}
            placeholder={Imgs.Img06_placeholder}
            desc="Three opportunity areas pointing towards shifting the culture"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Insights to support opportunities areas</Header>
            <Text>
              Each opportunity area was supported by synthesized insights,
              accompanied by stories and quotes from workers. We presented
              briefs with problem definitions, How Might We questions, and
              inspiration for tangible outcomes at the final share. A holistic
              plan outlined potential future projects to address each
              opportunity area.
            </Text>
          </ParagraphSection>
          <ImageGrid
            img1={Imgs.Img07}
            img2={Imgs.Img08}
            img1_placeholder={Imgs.Img07_placeholder}
            img2_placeholder={Imgs.Img08_placeholder}
            img1_desc="Research Insight"
            img2_desc="Quote supporting the insight"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Opportunity areas</Header>
            <Text>
              During the final share back, we presented three concise briefs,
              each featuring a problem definition, a How Might We question, and
              inspirational elements for tangible project outcomes.
              Additionally, we outlined a comprehensive plan for holistic
              improvement across multiple future projects.
            </Text>
          </ParagraphSection>
          <ImageGrid
            img1={Imgs.Img09}
            img2={Imgs.Img10}
            img3={Imgs.Img11}
            img4={Imgs.Img12}
            img1_placeholder={Imgs.Img09_placeholder}
            img2_placeholder={Imgs.Img10_placeholder}
            img3_placeholder={Imgs.Img11_placeholder}
            img4_placeholder={Imgs.Img12_placeholder}
          />

          <ParagraphSection>
            <Header>Prioritization workshop</Header>
            <Text>
              In an interactive session, we engaged the audience, allowing them
              to evaluate and prioritize the briefs. This insightful exchange
              provided valuable perspectives, guiding us toward a clearer
              understanding of potential next steps in our project journey.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img13}
            placeholder={Imgs.Img13_placeholder}
            desc="Workshop and workshop activities"
          />

          <ParagraphSection>
            <Header>Learning Layer</Header>
            <Text>
              The project involved client members in a design thinking learning
              journey. Termed "IDEO Students," they participated in the process,
              conducted interviews, and presented reflections and mini-proposals
              at the project's conclusion.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img14}
            placeholder={Imgs.Img14_placeholder}
            desc="IDEO Students"
          />

          {/* IN OTHERS EYES */}
          <div className="bg-orange-50 text-emerald-600">
            <LightBgSection>
              <HeaderBig>In my collaborators' eyes</HeaderBig>
              <TwoColumns>
                <div className="pt-8 md:pt-6">
                  <TextItalic>
                    It was great working with you! Having you as a PL was very
                    encouraging to me and your calmness and overall perspective
                    made the project run smoothly. Also, your provocation made
                    the insights and beacon projects stronger. I hope to work
                    with you again!
                  </TextItalic>
                  <TextSmall>- Hiromi Hayashihara, Design Researcher</TextSmall>
                </div>
                <div className="pt-2 md:pt-6">
                  <TextItalic>
                    As the project lead, Piotr was willing to listen to any
                    feedback, gave me enough space to speak my mind, and was
                    always ready to support any request made. I could
                    confidently work with the team without much difficulty even
                    though I joined the project two days before the kick-off.
                  </TextItalic>
                  <TextSmall>- Mateus Situmorang, Design Researcher</TextSmall>
                </div>
              </TwoColumns>
            </LightBgSection>
          </div>
          {/* REFLECTIONS*/}
          <div className="bg-orange-50 text-emerald-600">
            <LightBgSection>
              <HeaderBig>My Learnings & Reflections</HeaderBig>
              <ThreeColumns>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>
                    Giving workers the space for honesty
                  </HeaderSmall>
                  <TextSmall>
                    Initially, the presence of plantation managers impacted
                    participant openness, hindering honest sharing. Recognizing
                    this, we adjusted by requesting fewer managers and
                    organizing smaller observation groups. Additionally, we
                    relocated interviews from office proximity to workers' homes
                    for a more conducive atmosphere.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>
                    Including the decision makers earlier
                  </HeaderSmall>
                  <TextSmall>
                    Due to time constraints and stakeholders' busy schedules, we
                    missed early feedback opportunities. While it didn't result
                    in major issues, a proactive approach to involve
                    stakeholders early on could have enhanced content sharpness
                    and accelerated the learning process.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>Spacing the Learning Layer</HeaderSmall>
                  <TextSmall>
                    While our plan allotted 20% of project time to students, we
                    underestimated the energy required for a high-quality
                    learning experience. It was a rewarding experience to help
                    students grow their design thinking skills but an extra team
                    member could have alleviated the workload, allowing a more
                    focused approach to both components.
                  </TextSmall>
                </div>
              </ThreeColumns>
            </LightBgSection>
          </div>
        </div>
      </div>
      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/blood_testing/`}>
          <ProjectButtonSmall src={ImageBgNectar}>
            <ProjectTagSmall>Design & Detail</ProjectTagSmall>
            <HeaderBig>
              Building a blood testing device for an Emergency Room environment
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/assistant/`}>
          <ProjectButtonSmall src={ImageBgLincoln}>
            <ProjectTagSmall>Design & Guide</ProjectTagSmall>
            <HeaderBig>
              Defining the role of Digital Assistant for highly demanding car
              owners
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
      </ProjectFooter>
    </div>
  );
};

export default ProjectAkar;
