import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { transform } from "framer-motion";

const RotatingRectangle = ({ mousePosition, position }) => {
  const rectRef = useRef(null);
  const [rectCenter, setRectCenter] = useState({ x: 0, y: 0 });
  const [opacityDefault, setOpacity] = useState(position.opacity);

  // useEffect(() => {
  //   const rect = rectRef.current.getBoundingClientRect();
  //   setRectCenter({
  //     x: rect.left + rect.width / 2,
  //     y: rect.top + rect.height / 2,
  //   });
  //   // setOpacity(100 / position.x);
  // }, [mousePosition]);

  // const angle =
  //   Math.atan2(mousePosition.y - rectCenter.y, mousePosition.x - rectCenter.x) *
  //   (180 / Math.PI);

  // const distance = Math.hypot(
  //   mousePosition.x - rectCenter.x,
  //   mousePosition.y - rectCenter.y
  // );
  // const maxDistance = Math.hypot(window.innerWidth / 2, window.innerHeight / 2);

  // const opacityTransformer = transform([maxDistance, 0], [0, 1.0]);
  // const widthTransformer = transform([0, maxDistance], [0, 10]);

  // const opacity = opacityTransformer(distance);
  // const width = widthTransformer(distance);

  return (
    <motion.div>
      <motion.div
        ref={rectRef}
        className="absolute bg-[#34734B]"
        animate={[
          {
            rotate: 180,
            opacity: position.opacity,
            width: 2 + position.delay * 2,
          },
        ]}
        initial={{
          rotate: 0,
          opacity: position.opacity,
          width: 2 + position.delay * 2,
        }}
        transition={{
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
          duration: 8,
          delay: position.delay * 3,
        }}
        style={{
          height: `${position.height}px`,
          left: `${position.x}px`,
          top: `${position.y}px`,
          transformOrigin: "center",
          transform: "translate(-50%, -50%)",
        }}
      />
    </motion.div>
  );
};

export default RotatingRectangle;
