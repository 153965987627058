import TeamSection from "./TeamSection";
import TeamMember from "./TeamMember";

import ImgTeam01 from "../assets/nectar/ImgTeam01.png";
import ImgTeam02 from "../assets/nectar/ImgTeam02.png";
import ImgTeam03 from "../assets/nectar/ImgTeam03.png";
import ImgTeam04 from "../assets/nectar/ImgTeam04.png";
import ImgTeam05 from "../assets/nectar/ImgTeam05.png";

export default function TeamSectionNectar({ children }) {
  return (
    <TeamSection>
      <TeamMember name="Junyi Zhu" role="Project Lead" profile={ImgTeam01} />
      <TeamMember
        name="Piotr Kuklo"
        role="Lead Interaction Designer"
        profile={ImgTeam02}
      />
      <TeamMember
        name="Sophie He"
        role="Industrial Designer"
        profile={ImgTeam03}
      />
      <TeamMember
        name="Janette Haw"
        role="Communication Designer"
        profile={ImgTeam04}
      />
      <TeamMember
        name="Inder Sachdev"
        role="Industrial Designer"
        profile={ImgTeam05}
      />
    </TeamSection>
  );
}
