import { motion, AnimatePresence } from "framer-motion";

export default function TeamMember({ name, role, profile }) {
  return (
    <AnimatePresence>
      <motion.div
        className="flex gap-4 text-neutral-500 py-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0 }}
      >
        <img
          alt="Profile"
          className="h-14 bg-neutral-200 rounded-full"
          src={profile}
        />
        <div className="pt-1.5">
          <p>{name}</p>
          <p className="text-xs md:text-sm">{role}</p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
