import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { CubeTransparentIcon } from "@heroicons/react/24/outline";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import CV from "../assets/home/piotrkuklo_resume.pdf";

import ProjectList from "./ProjectList";
// import WorkInProgress from "./WorkInProgress";
import Logos from "../components/Logos";

import ProfilePhoto from "../assets/home/profile.png";
import frogLogo from "../assets/home/frog.png";
import ideoLogo from "../assets/home/ideo.png";

import GoUp from "../components/GoUp";
import { CursorArrowRaysIcon, UsersIcon } from "@heroicons/react/24/solid";
import InteractiveBackground from "../components/InteractiveBackground";

const Home = () => {
  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // resizing window
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const handleMouseMove = (e) => {
  //   setMousePosition({ x: e.clientX, y: e.clientY }); /// modify to accommodate top bar height
  // };
  //

  return (
    <div
      className="relative h-full w-full overflow-hidden mb-0 pb-0"
      // onMouseMove={handleMouseMove}
    >
      <div className="opacity-40 md:opacity-90">
        <InteractiveBackground
          // mousePosition={mousePosition}
          windowSize={windowSize}
        />
      </div>
      <div className="relative z-10">
        {WelcomeTop()}
        {/* PROJECTS */}
        <div id="projects" />
        <ProjectList />

        {/* ABOUT ME */}
        <div className="bg-white py-16 md:py-26">
          <div id="aboutme" className="max-w-screen-xl mx-auto  md:px-12 px-6">
            <motion.div
              className="lg:flex max-w-screen-xl my-12 md:my-28 "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              exit={{ opacity: 0 }}
            >
              <div id="left" className="lg:w-7/12">
                <h1 className="text-gray-700 text-4xl lg:text-4xl font-bold md:mr-10">
                  With 9+ years of experience, I've worked across diverse
                  industries, assisting companies in reaching their innovation
                  goals.
                </h1>
                <p className="leading-normal text-lg text-gray-500 mt-8 md:text-xl lg:leading-normal md:leading-normal md:mr-10">
                  I merge design expertise with technical competence to craft
                  services that meet emerging user needs, push boundaries, and
                  deliver scalable value. Whether solving systemic challenges or
                  delving into micro-interactions, I find joy in both.
                </p>
              </div>
              <div id="right" className="hidden lg:block md:w-5/12">
                <Experience
                  company="IDEO"
                  years="2018-2023"
                  role="Design Lead"
                />
                <Experience
                  company="frog design"
                  years="2016"
                  role="Interaction Designer"
                />
                <Experience
                  company="Freelancing"
                  years="2011-2015"
                  role="Product Designer"
                />
                <Experience
                  company="White Cat Studio"
                  years="2011-2014"
                  role="Communication Designer"
                />
              </div>
            </motion.div>
            <Logos />
            {/* 3 COLUMNS */}
            <div className="lg:grid lg:grid-cols-3 gap-10 md:pt-20 pt-2 md:pb-20 pb-8  ">
              <div className="py-2 mt-4">
                <div className="text-right flex gap-2 text-gray-700">
                  <h1 className="text-xl lg:text-2xl font-bold ">
                    Design Lead
                  </h1>
                  <UsersIcon
                    className="h-6 w-6 ml-1 mt-0.5"
                    aria-hidden="true"
                  />
                </div>
                <p className="text-lg mt-1 lg:mt-3 text-gray-500">
                  I guide multidisciplinary designers and researchers through
                  dynamic and messy innovation processes. I create conditions
                  that allow everyone to bring their best selves with optimal
                  creativity, ensuring impactful outcomes.
                </p>
              </div>

              <div className="py-2 mt-4">
                <div className="text-right flex gap-2 text-gray-700">
                  <h1 className="text-xl lg:text-2xl font-bold ">
                    Interaction Designer
                  </h1>
                  <CursorArrowRaysIcon
                    className="h-6 w-6 ml-1 mt-0.5"
                    aria-hidden="true"
                  />
                </div>
                <p className="text-lg mt-1 lg:mt-3 text-gray-500">
                  I'm obsessed with understanding how things work and how users
                  experience them. I build to think and align stakeholders' and
                  users' needs through tangibility. It accelerates our
                  problem-solving and confidence in the team's outcome.
                </p>
              </div>
              <div className="py-2 mt-4">
                <div className="text-right flex gap-2 text-gray-700">
                  <h1 className="text-xl lg:text-2xl font-bold ">
                    Technologist
                  </h1>
                  <CubeTransparentIcon
                    className="h-6 w-6 ml-1 mt-0.5"
                    aria-hidden="true"
                  />
                </div>
                <p className="text-lg mt-1 lg:mt-3 text-gray-500">
                  Originally using technology to build and orchestrate
                  prototypes, my role evolved into analyzing the positive and
                  negative impacts of technology. I ensure solutions bring
                  positive change without marginalizing any group.
                </p>
              </div>
            </div>
          </div>
        </div>
        {FooterHome()}
      </div>
      {/* FOOTER*/}
    </div>
  );
  function Experience({ company, years, role }) {
    return (
      <div className="flex py-4 border-b-2 border-solid border-gray-100">
        <div className="md:w-32 lg:w-60">
          <div className="font-bold text-gray-700 ">{company}</div>
          <div className="text-gray-500">{years}</div>
        </div>
        <div className="text-gray-500">{role}</div>
      </div>
    );
  }
  function WelcomeTop() {
    return (
      <AnimatePresence>
        <motion.div
          className="md:flex max-w-screen-xl mx-auto my-12 md:my-28 px-8 md:px-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="w-100 md:w-8/12">
            <h1 className="text-gray-700 text-4xl lg:text-6xl font-bold md:mr-10">
              Design Lead specializing in Product Strategy and Innovation.
            </h1>
            <p className="leading-normal text-lg text-gray-500 mt-8 md:text-xl lg:leading-normal md:leading-normal md:mr-10">
              I’m currently seeking opportunities to lead multidisciplinary
              teams and elevate everyday experiences through human-center
              approach & cutting-edge technology.
            </p>
            <Links />

            <div className="pt-6 pb-10 flex text-sm sm:text-normal text-gray-500">
              <div className="">
                <img className="mt-1 h-10" alt="frog Design" src={frogLogo} />
                <p className="">Munich</p>
              </div>
              <div className="ml-6 md:ml-10 w-100">
                <img className="mt-1 h-10 " alt="IDEO" src={ideoLogo} />
                <p className="">Palo Alto, San Francisco, Shanghai</p>
              </div>
            </div>
          </div>
          <div className="w-4/12 hidden md:block grid justify-items-center">
            <img
              className="px-4 center mx-auto"
              alt="Piotr Kuklo"
              src={ProfilePhoto}
            />
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }
  function Links() {
    return (
      <div className="relative -top-6 flex gap-1 md:gap-4 leading-normal underline text-base text-gray-500 mt-8 md:text-xl lg:leading-normal md:leading-normal md:mr-10">
        <a href="mailto:piotrkuklo@gmail.com">
          <OneLink link="">Email</OneLink>
        </a>{" "}
        <a target="_blank" href="https://www.linkedin.com/in/piotr-kuklo/">
          <OneLink>Linkedin</OneLink>
        </a>
        <a target="_blank" href={CV}>
          <OneLink>
            <span className="hidden md:inline">CV / </span>Resume
          </OneLink>
        </a>
      </div>
    );
  }
  function OneLink({ link, children }) {
    return (
      <motion.div
        className="w-auto font-medium flex items-center"
        whileHover={{
          scale: 0.9,
          opacity: 0.6,
          transition: { ease: "anticipate", duration: 0.6 },
        }}
      >
        <div className="py-4">{children}</div>
        <ArrowUpRightIcon className="ml-1 h-4 w-4 mr-6" aria-hidden="true" />
      </motion.div>
    );
  }

  function FooterHome() {
    return (
      <div id="contact" className="bg-gray-900 text-neutral-50 mx-auto">
        <div className="max-w-screen-xl mx-auto py-16 px-12">
          <div className="">
            <h1 className="text-5xl font-bold mt-4">Let's chat</h1>
            <h3 className="text-2xl mt-4">
              Send me{" "}
              <a className="underline" href="mailto:piotrkuklo@gmail.com">
                a message
              </a>{" "}
              or{" "}
              <a
                className="underline"
                href="https://www.linkedin.com/in/piotr-kuklo/"
              >
                connect on Linkedin
              </a>
              .
            </h3>
          </div>
          <div className="text-sm md:text-base py-20 flex justify-between ">
            <div>© 2024, Hand coded by Piotr Kuklo</div>
            <GoUp />
          </div>
        </div>
      </div>
    );
  }
};

export default Home;
