import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import RotatingRectangle from "./RotatingRectangle";
import RotatingRectangleSmooth from "./RotatingRectangleSmooth";

const InteractiveBackground = ({ windowSize }) => {
  const [rectangles, setRectangles] = useState([]);
  const margin = 0;
  const width = 80;
  const height = 80;
  const startingX = windowSize.width / 2 - windowSize.width * 0.03;
  const startingY = 0;

  const colDistance = width + margin;
  const rowDistance = height + margin;

  useEffect(() => {
    generateGrid();
  }, [windowSize]);

  const generateGrid = () => {
    const numCols = Math.floor(windowSize.width / colDistance); // 200px width + 20px margin
    const numRows = Math.floor(windowSize.height / rowDistance); // 50px height + 20px margin
    const newRectangles = [];

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        newRectangles.push({
          key: `${row}-${col}`,
          x: startingX + col * colDistance,
          y: startingY + row * rowDistance,
          opacity: (col + 1) / numCols / 6 + (row + 1) / numRows / 16,
          delay: (col + 1) / numCols + (row + 1) / numRows,
          height: height,
        });
      }
    }

    setRectangles(newRectangles);
  };

  return (
    <div
      className="absolute -top-12 md:-top-24 inset-0 overflow-hidden bg-gradient-to-br from-10% from-transparent to-[#E3FFE6]"
      style={{ zIndex: -1 }}
    >
      <AnimatePresence>
        <motion.div
          initial={{
            opacity: 0,
            x: 0,
          }}
          animate={{ opacity: 1, x: 60 }}
          transition={{ ease: "easeInOut", duration: 1 }}
        >
          {rectangles.map((rect) => (
            <RotatingRectangle key={rect.key} position={rect} />
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default InteractiveBackground;
