import { motion, AnimatePresence } from "framer-motion";

export default function ParagraphSection({ children }) {
  return (
    <AnimatePresence>
      <motion.div
        className="mx-auto px-8 md:px-20 pt-16 pb-0"
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
