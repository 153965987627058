import ImgHero from "./hero.jpg";
import Img01 from "./01.jpg";
import Img01_placeholder from "./01_placeholder.jpg";
import Img02 from "./02.jpg";
import Img02_placeholder from "./02_placeholder.jpg";
import Img03 from "./03.jpg";
import Img03_placeholder from "./03_placeholder.jpg";
import Img04 from "./04.jpg";
import Img04_placeholder from "./04_placeholder.jpg";
import Img05 from "./05.jpg";
import Img05_placeholder from "./05_placeholder.jpg";
import Img06 from "./06.jpg";
import Img06_placeholder from "./06_placeholder.jpg";
import Img07 from "./07.jpg";
import Img07_placeholder from "./07_placeholder.jpg";
import Img08 from "./08.jpg";
import Img08_placeholder from "./08_placeholder.jpg";
import Img09 from "./09.jpg";
import Img09_placeholder from "./09_placeholder.jpg";
import Img10 from "./10.jpg";
import Img10_placeholder from "./10_placeholder.jpg";
import Img11 from "./11.jpg";
import Img11_placeholder from "./11_placeholder.jpg";
import Img12 from "./12.jpg";
import Img12_placeholder from "./12_placeholder.jpg";
import Img13 from "./13.jpg";
import Img13_placeholder from "./13_placeholder.jpg";
import Img14 from "./14.jpg";
import Img14_placeholder from "./14_placeholder.jpg";

const Imgs = {
  ImgHero,
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  Img06,
  Img07,
  Img08,
  Img09,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img01_placeholder,
  Img02_placeholder,
  Img03_placeholder,
  Img04_placeholder,
  Img05_placeholder,
  Img06_placeholder,
  Img07_placeholder,
  Img08_placeholder,
  Img09_placeholder,
  Img10_placeholder,
  Img11_placeholder,
  Img12_placeholder,
  Img13_placeholder,
  Img14_placeholder,
};
export default Imgs;
