import img1 from "../assets/home/logo1.png";
import img2 from "../assets/home/logo2.png";
import img3 from "../assets/home/logo3.png";
import img4 from "../assets/home/logo4.png";
import img5 from "../assets/home/logo5.png";
import img6 from "../assets/home/logo6.png";
import img7 from "../assets/home/logo7.png";
import img8 from "../assets/home/logo8.png";

export default function Logos() {
  return (
    <div className="pb-20 sm:pb-28 pt-10 sm:pt-12">
      <div className="mx-auto max-w-7xl ">
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <img
            className="col-span-2 lg:col-span-1 max-h-12 w-full object-contain"
            src={img1}
            alt="Ford"
            width="158"
            height="48"
          />
          <img
            className="col-span-2 lg:col-span-1  max-h-12 w-full object-contain"
            src={img2}
            alt="Google"
            width="158"
            height="48"
          />
          <img
            className="col-span-2 lg:col-span-1 max-h-12 w-full object-contain"
            src={img3}
            alt="Grab"
            width="158"
            height="48"
          />
          <img
            className="col-span-2 sm:col-start-3 lg:col-span-1 max-h-12 w-full object-contain "
            src={img4}
            alt="IKEA"
            width="158"
            height="48"
          />
          <img
            className="col-span-2 col-start-1 sm:col-start-auto lg:col-span-1 lg:col-start-1 max-h-12 w-full object-contain"
            src={img5}
            alt="Loreal"
            width="158"
            height="48"
          />
          <img
            className="col-span-2  sm:col-start-3 lg:col-span-1 max-h-16 w-full object-contain"
            src={img6}
            alt="LIDL"
            width="158"
            height="48"
          />
          <img
            className="col-span-2 sm:col-start-auto lg:col-span-1 max-h-20 w-full object-contain "
            src={img7}
            alt="Gates"
            width="158"
            height="48"
          />
          <img
            className="col-span-2  sm:col-start-3 lg:col-span-1 max-h-12 w-full object-contain"
            src={img8}
            alt="Michelin"
            width="158"
            height="48"
          />
        </div>
      </div>
    </div>
  );
}
